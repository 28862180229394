
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { Children } from 'react';
import { Modal, Container, ButtonGroup, Button, Row, Col } from 'react-bootstrap';
import RenderOptionButtonComponent from './RenderOptionButtonComponent';
import closeIcon from "../../icons/icon/CloseModal.svg"


function SelectPopupButtonsComponent({ name, cell, choices, close, show, chiudi }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const showName = name.includes("100%") ? "Offerta 100% Rinnovabili" : name

    const closeCopy = ["C25", "C26", "C27"].includes(cell) ? "Chiudi" : "Annulla"

    return (
        <Modal 
            show={show} 
            centered={true}
            role="dialog"
            aria-label={"Scegli " + name}
            className="select-popup"
            animation={false}
            fullscreen={true} 
            backdrop={false}>

            <Container fluid className="title d-flex justify-content-between align-items-center" >
                <div className="field-name">{showName}</div>
            </Container>
                {choices.find(x => x === "Non so" || x === "Non lo so") && 
            <Container fluid className="non-lo-so">
                <Row className="so-non-so mt-2">
                    <Col>
                    <ButtonGroup className="w-100  mt-2">
                        <Button role="listitem"  className={currentValue !== "Non so" ? "sel" : ""}
                          onClick={() => dispatch(set({
                            cell: cell,
                            value: undefined
                        }))}>Conosco {showName}</Button>
                        <Button role="listitem" className={currentValue === "Non so" ? "sel" : ""}
                            onClick={() => dispatch(set({
                                cell: cell,
                                value: "Non so"
                            }))}
                        >Non so</Button>
                    </ButtonGroup>
                    </Col>
                </Row>
            </Container>}
            
            <Container role='list' className={"options row align-self-center pt-2" + (currentValue === "Non so" ? "disabled" : "") }>
                {choices.filter(x => x !== "Non so").map(choice => <RenderOptionButtonComponent
                    name={showName}
                    cell={cell}
                    action={choice}
                    key={choice}
                />)}
            </Container>
            <div className="confirm d-flex justify-content-center align-items-center">
                <button disabled={currentValue === undefined || currentValue === ""}
                    onClick={close}
                >CONFERMA</button>
            </div>
            <button style={{position: "absolute", top: "16px", right: "16px", zIndex: 1000002}} 
                className="close-button accent no-border"  
                onClick={() => { dispatch(set({cell: cell, value: undefined})); close()}}>
              <span className="d-none d-lg-block">  { closeCopy }</span>
            <img height={14} src={closeIcon} alt={"Chiudi scelta " + name }/></button>
        </Modal>
    );
}

export default SelectPopupButtonsComponent;
