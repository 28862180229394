import React, {useState} from 'react';
import { Container, Row } from 'react-bootstrap';
import FinalHeaderComponent from './final/FinalHeaderMobileComponent';
import BeneficiFinaliComponent from './final/BeneficiFinaliComponent';
import ReviewOrDownload from './final/ReviewOrDownloadComponent';
import BookAppointment from './final/BookAppointmentMobileComponent';
import Footer from './final/FooterComponent';
import ContactsModalComponent from './ContactsModalComponent';

function RisparmioFinaleComponent({ main}) {
const [showAppointment, setShowAppointment] = useState(false);
const [showModal, setShowModal] = useState(false);
return (<div fluid className="final d-lg-none">
        <FinalHeaderComponent />
        <main ref={main} >
        <BeneficiFinaliComponent />
        <ReviewOrDownload changeModal={(val)=> setShowModal(val)} />
        <ContactsModalComponent appointment={true} show={showAppointment} close={() => setShowAppointment(false)} />
        <ContactsModalComponent appointment={false} show={showModal} close={() => setShowModal(false)} />
        <BookAppointment changeModal={(val) => setShowAppointment(val)} />
        {/* <Footer /> */}
        </main>
    </div>
)
}

export default RisparmioFinaleComponent

