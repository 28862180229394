
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import { current } from '@reduxjs/toolkit';

function SliderComponent({ name, cell }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]

    return (
        <div className={"text-component d-flex flex-column input-border justify-content-center " + (data ? "filled" : "") }>
             <div className="fieldname">{name}</div>
            <input
                className=''
                placeholder={name}
                type="number"
                value={currentValue} onChange={(e) => {
                    dispatch(set({
                        cell: cell,
                        value: e.target.value
                    }))
                }}
            />
        </div>
    );
}

export default SliderComponent;