import React, {useState, useEffect} from 'react'
import { Container, Row, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import RiepilogoModalComponent from '../RiepilogoModalComponent';


function ReviewOrDownload ({changeModal}) {
    const dispatch = useDispatch();
    // const checkIfProjectIsComplete = utils.checkIfProjectIsComplete
    const [showRiepilogo, setShowRiepilogo] = useState(false);
    const data = useSelector(get);

    return <Container className="review-or-download">
        <div className='d-block d-md-flex justify-content-center flex-md-column flex-lg-row align-items-center align-items-lg-start'> 
            <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
            <Button onClick={()=> setShowRiepilogo(true)} className="d-none outline mr-16">RIVEDI I DATI INSERITI</Button>
            <div>
                {/* <a href={data["ProjectUrl"]} target="_blank"> */}
                    {/* <Button onClick={() => changeModal(true)} className="outline">RICEVI IL REPORT</Button> */}
                {/* </a> */}
                {/* <div className="text-center mt-8">Lascia i tuoi dati e ricevi il report via mail</div> */}
            </div>
        </div>
    </Container>
}

export default ReviewOrDownload;
