
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import fotovoltaico from "../../icons/icon/fotovoltaico.svg"
import batteria from "../../icons/icon/batteria.svg"


function SelectAtViewFotoComponent({ name, cell, description, choices }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    // const currentValue = data[cell]
    const icons = [fotovoltaico, batteria]
    const keyDown = (e, x, i)=> {
        // console.log(e)
        if (e.key === "ArrowLeft" || e.key === "ArrowUp") { 
            e.preventDefault();
            const sibling = e.currentTarget.previousElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
        }
        else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
            e.preventDefault();
            const sibling = e.currentTarget.nextElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
         }
        else if (e.key === "Enter") {
            dispatch(set({
                cell: x.cell,
                value: true
            }))
            for (let j = 0; j < choices.length; j++) {
                 if (j !== i) {
                     dispatch(set({
                         cell: choices[j].cell,
                         value: false
                     }))
                 }
             }
        }
    }

    return (
        <div className={"selectatview-component mb-16 " + (data ? "filled" : "")}>
            <div className="title">{name}
                {description && <div className="description">{description}</div>}
            </div>
            {choices && <div className="choice-box">
                {choices.map((x, i) =>
                    <button key={i} 
                    onKeyDown={e => keyDown(e, x,i)}
                    onClick={(e) => {
                        dispatch(set({
                            cell: x.cell,
                            value: true
                        }))
                        for (let j = 0; j < choices.length; j++) {
                             if (j !== i) {
                                 dispatch(set({
                                     cell: choices[j].cell,
                                     value: false
                                 }))
                             }
                         }
                    }}
                     
                    className={"w-100 single-box d-flex align-items-center no-bg " + (data[x.cell] === true && data["C54"] !== undefined ? "selected": "")} >
                        <input type="radio" 
                            checked={data[x.cell] === true}
                            className="choice" 
                            name={name ?? cell} 
                            value={x.text}
                            id={x.text}
                            tabIndex={-1}
                            style={{ outline: "none"}}
                            ></input>
                        <img src={icons[x.image]} alt="" />
                        <label className="flex-grow-1 text-start" htmlFor={x.text}>{x.text}</label>
                    </button>
                )
                }
            </div>}
        </div>
    );
}

export default SelectAtViewFotoComponent;