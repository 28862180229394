import React, { useEffect,useState } from 'react';
import edit from "../../icons/icon/edit.svg"
import thumb from "../../icons/icon/slider.png"
import { useDispatch } from 'react-redux';
import { get, reset } from '../../reducers/data'

function SliderWithThumbComponent({cell, currentValue, setCurrentValue, 
    min, max, step, unit, name,
     disabled, extraClass, forceValue, moveArrow, error
     }){
    useEffect(() => { 
        moveArrow({target: document.querySelector(".slider" + cell + " input")})
    }, [])

    const dispatch = useDispatch()
    const [internalValue, setInternalValue] = useState(currentValue)
    const checkAndSend = (e) => {
        // if (min && e < min)
        //     return setCurrentValue(min)
        // if (max && e > max)
        //     return setCurrentValue(max)
        setInternalValue(e)
        setCurrentValue(e)
    }
    const style={
        zIndex: 100,
        position: "relative",
        marginBottom: "4px",
        paddingRight:"8px",
        textAlign: "right",
    }
   
    return (
        <div className={"sliderwiththumb slider" + cell + " " + (extraClass ?? "")}>
            <div className="shad1 d-flex justify-content-center align-items-center mb-16">
                <div className="baloon">
                    <input type="number" style={style}
                        disabled={currentValue === "N/A" || disabled}
                        aria-label={"Input numerico per  " + name + ( min ? " minimo " + min : "") + ( max ? " massimo " + max : "")}
                        max={max} 
                        min={min}
                        aria-invalid={error ? true : false}
                        aria-errormessage={"err"+cell}
                        onBlur={(e) => { moveArrow(e); setCurrentValue(e.target.value) }} 
                        value={internalValue} 
                        onChange={(e) => {
                             moveArrow(e);
                             setInternalValue(e.currentTarget.value)
                        }} 
                        />
                    <span className="unit" aria-hidden={true}>{unit}</span>
                    {error && disabled !== true && <div id={"err" + cell } className="visually-hidden error">{error}</div> }
                    <img src={edit} alt="" role='presentation' style={style} onClick={(e)=> e.target.parentElement.querySelector("input").focus()} />
                </div>
            </div>
          <div className="thumb" aria-hidden={true} ></div>
          <div className="fill" aria-hidden={true}></div>
            <input type="range"
                aria-hidden={true}
                tabIndex={-1}
                aria-label={"trascina il dito verso l'alto o il basso per cambiare valore"}
                step={step || 1}
                disabled={currentValue === "N/A" || disabled}
                className="mb-16 range" min={min} max={max} value={currentValue}
                onChange={(e) => { moveArrow(e); checkAndSend(e.currentTarget.value)}} />
                <div className="markers" aria-hidden={true}>
                <div aria-hidden={true}>{min}</div>
                <div aria-hidden={true}>{max}</div>
            </div>
            { disabled === true ? <></> :<div id="liveRegion" 
                aria-hidden={true}
                aria-live="polite" 
                aria-atomic="true" 
                className="visually-hidden">Valore attuale {currentValue}. {error ?? ""}</div>}
        </div>
    )

}

export default SliderWithThumbComponent;