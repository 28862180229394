import React, {useState} from 'react';
import RiepilogoModalComponent from './RiepilogoModalComponent';

function RiepilogoComponent() {
const [showRiepilogo, setShowRiepilogo] = useState(false);
return (<>
        <div onClick={() => setShowRiepilogo(true)} className="riepilogo-label mb-16 text-center d-none text-md-start d-lg-none">
            <span>Riepilogo</span></div>
        <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
    </>
)
}

export default RiepilogoComponent

