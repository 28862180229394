import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../../reducers/data'
import { Row, Col, Container, Accordion  } from 'react-bootstrap'
import checkIcon from '../../icons/check_circle.svg'
import emptyIcon from '../../icons/icon/empty.svg'
import utils from "../../utils/index.js"


const RiepilogoBlockComponent = ({ page, index }) => {
  // const dispatch = useDispatch()
  const data = useSelector(get)
  const pageIndex = data["pageIndex"]

  const tr = utils.testRendering
  const isComplete = pageIndex >= index
  //  && page
  //       .items?.filter(x => x.cell && tr(x, data))
  //       .every(field => data[field.cell] !== undefined && data[field.cell] !== "")

  if (!isComplete) console.log(page
            .items?.filter(x => x.cell && tr(x, data))
            .filter(field => data[field.cell]))

   const printData = (valore, item) => {
    if (valore === undefined) return "Non inserito"
    if (valore === true)  return "Si"
    if (valore === false) return "No"
    return valore
   }

   const tempIndex = index < 3 ? index +1 : index
   const title = index === 3 ? "Report informativo" : (tempIndex) +". " +page.title

   const toPrint = page.items?.filter(x => x.cell && tr(x, data) && x.name)
  //  if (toPrint.length === 0) return null
  return (
    <>
      <Container className="d-flex title-box">
        <img src={isComplete ? checkIcon : emptyIcon} width={21} height={21} alt='check' />
        <div className='riepilogo-title ms-2'>{title}</div>
      </Container>
      {index !== 3 && toPrint.length > 0 &&<div className="answers">
        {toPrint.map((field, i) => <Accordion key={i} alwaysOpen>
            <Accordion.Header>{field.name}</Accordion.Header>
            <Accordion.Body>{printData(data[field.cell], field) }</Accordion.Body>
        </Accordion>)}
      </div> }
    </>
  )
}

export default RiepilogoBlockComponent
