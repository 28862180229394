
import closeIcon from "../../icons/icon/close.svg"
import closeIconDesktop from "../../icons/icon/closesmall.svg"

function InfoPopupComponent({ title, subtitle, close }) {
        return (<div role="dialog">
                <div className="arrow-up"></div>
                <div className="info-popup shad4">
                        <div className='content'>
                                <div className="title d-flex align-items-center justify-content-between">
                                        <div tabIndex={0}>{title}</div>
                                     
                                </div>
                                <div className="text" tabIndex={0} dangerouslySetInnerHTML={{ __html: subtitle }}>
                             
                                </div>
                                <button onClick={close} style={{ background: "unset", border: 0, position:"absolute", right: "8px", top: "8px" }}> 
                                                <img src={closeIcon} className="d-lg-none" alt="Chiudi la finestra informativa" />
                                                <img src={closeIconDesktop} className="d-none d-lg-inline" alt="Chiudi la finestra informativa" />
                                </button>
                                {/* <button onClick={close} className="big-accent">Conferma</button> */}
                        </div>
                </div>
        </div>)
}

export default InfoPopupComponent;