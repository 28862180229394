import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../../reducers/data'
import React, { useState } from 'react'
import euro from '../../icons/icon/europeryear.svg'
import { Modal, Container, ButtonGroup,Col, Row, Carousel } from 'react-bootstrap'
import closeIcon from '../../icons/icon/close.svg'

import bolletta2 from '../../icons/bolletta2.svg'
import arrow from "../../icons/icon/chevron_right.svg"
import FacSimileComponent from './FacSimileComponent'

function ControlledCarousel () {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      id='bolletta-carousel'
      activeIndex={index}
      indicators={false}
      onSelect={handleSelect}
      controls={false}
    >
      
      <Carousel.Item>
        <img className='d-block w-100' src={bolletta2} alt="" />
        <div className="visually-hidden"></div>
        {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
      </Carousel.Item>
      {/* <Carousel.Item>
        <img className='d-block w-100' src={bolletta1} alt='First slide' />
      </Carousel.Item> */}
    </Carousel>
  )
}

function ModalPictures ({ show, close }) {
  return (
    <Modal
      role="dialog"
      show={show}
      centered={true}
      className='select-popup modal-pictures'
      animation={false}
      fullscreen={true}
      backdrop={false}
      aria-label="Visualizza fac-simile bolletta"
    >
      <Container
        fluid
        className='title d-flex justify-content-between align-items-center'
      >
        <div className='field-name'>Visualizza FAC-SIMILE</div>
        <div className='accent' onClick={close} tabIndex={0}>
          <span className="d-inline d-lg-none" >Chiudi</span> <img height={14} src={closeIcon} alt='x close' />
        </div>
      </Container>

      <Container fluid>
        <Row>
          <ControlledCarousel />
        </Row>
        <Row >
            <Col className="p-4" >
                <b> Pagina 3 della bolletta</b> <br />
                {/* Il dato sulla spesa annua per l'utenza è riportato nella terza pagina della bolletta Enel. Puoi trovarlo alla voce "Spesa annua", all'interno del box informativo "Comunicazioni per la tua offerta e i tuoi servizi" situato nella colonna di sinistra della pagina. Il valore della spesa annua è espresso in Euro. */}
                Se non riesci a trovare le informazioni, consulta la <b><a rel="noreferrer" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>        
            </Col>
        </Row>
      </Container>
    </Modal>
  )
}

function PercentageComponent ({ name, cell, canBeNull, after, subtitle }) {
  const dispatch = useDispatch()
  const data = useSelector(get)
  const [show, setShow] = React.useState(false)
  const [aggiornamento, setAggiornamento] = React.useState("")
  const currentValue = data[cell]

  const riscAmbiente = data['C25']
  const riscAcqua = data['C26']
  const pianoCottura = data['C27']

  const fieldsTotal = [
    {
      name: 'Elettrico',
      cell: 'C33'
    },
    {
      name: riscAmbiente,
      cell: 'F25'
    },
    {
      name: riscAcqua,
      cell: 'F26'
    },
    {
      name: pianoCottura,
      cell: 'F27'
    }
  ]
  const fields = []
  for (let field of fieldsTotal) {
    if (!field.name) continue
    if (!fields.map(x => x.name).includes(field.name)) fields.push(field)
  }

  const el = fields.shift()
  fields.push(el)

  // read le scelte di combustibili
  // crea i fields a partire da quelle scelte

  return (
    <div
      className={
        'percentage-component d-flex flex-column justify-content-center ' +
        (data ? 'filled' : '')
      }
    >
      <div
        className="fonti-facsimile"
        tabIndex={0}
        onClick={() => setShow(true)}
      >
        VISUALIZZA FAC-SIMILE <img src={arrow} alt="" />
        <span className="visually-hidden">Scopri dove trovare questa informazione nella bolletta Enel</span>
      </div>
      <FacSimileComponent 
        show={show} 
        close={() => setShow(false)} 
        img={bolletta2}
        n={3}
        copy={"Il dato sulla spesa annua per l'utenza è riportato nella terza pagina della bolletta Enel. Puoi trovarlo alla voce \"Spesa annua\", all'interno del box informativo \"Comunicazioni per la tua offerta e i tuoi servizi\" situato nella colonna di sinistra della pagina. Il valore della spesa annua è espresso in Euro."}
       /> 
      {fields.map((x, i) => (
        <><div className={(data[x.cell] !== undefined ? "active " : "" ) + 'input-border-half ' + (x.name === "Elettrico" ? "": "mb-16") + (currentValue === 'No' ? " dis" : "")  } key={i}>
          {data[x.cell] && <span className="prop-name">{x.name}</span> }
          <input 
            className="prop-value"
            placeholder={x.name}
            aria-roledescription='Textbox'
            alt={"Inserisci spesa annuale per " + x.name }
            min={0}
            type='number'
            value={data[x.cell] ?? ''}
            disabled={currentValue === 'No'}
            onInput={(e) => {
              // console.log("TRIGGER", e.nativeEvent)
            
              if (e.nativeEvent.data === null && e.nativeEvent.inputType !== "deleteContentBackward") { e.preventDefault(); return }
              if (e.nativeEvent.data === "." || e.nativeEvent.data === "e" || e.nativeEvent.data === "-") { e.preventDefault(); return }
              // console.log("aaa",e,e.target.value, e.target.value.replaceAll(".", ""))
              setAggiornamento("Valore per " + x.name + " aggiornato a " + e.target.value + " euro")
              dispatch(
                set({
                  cell: x.cell,
                  value: e.target.value
                })
              )
              dispatch(
                set({
                  cell: cell,
                  value: 'Si'
                })
              )     
            }
           }
           onBlur={e => {
            if (e.target.value.startsWith("-"))
              e.target.value = e.target.value.replaceAll("-", "")
              dispatch(
                set({
                  cell: x.cell,
                  value: e.target.value
                })
              )
           }}
          />
          <img src={euro} alt='€' />
        </div>
        {x.name === 'Elettrico' && <div className="subtitle mb-16">Senza canone RAI</div> } 
        
        </>
      ))}
      <div id="liveRegion" aria-live="polite" aria-atomic="true" className="visually-hidden">{aggiornamento}</div>

      {/* {subtitle && <div className='subtitle mb-16'>{subtitle}</div>} */}

      {after && <p className='disclaimer'>{after}</p>}

      {canBeNull && (
        <div className='d-flex d-row mb-16'>
          <input
          id="non-la-conosco"
            type='checkbox'
            aria-label='Non conosco la mia spesa annua per le fonti energetiche'
            checked={currentValue === 'No'}
                onKeyDown={e => {
              if (e.key === "Enter" || e.key === " ") {
                  dispatch(
                    set({ cell: cell, value: currentValue === 'No' ? 'Si' : 'No' })
                  )
                  for (let i = 0; i < fields.length; i++) {
                    dispatch(set({ cell: fields[i].cell, value: undefined }))
                  }
              }
            }}
            onChange={e => {
              dispatch(
                set({ cell: cell, value: data[cell] === 'No' ? 'Si' : 'No' })
              )
              for (let i = 0; i < fields.length; i++) {
                dispatch(set({ cell: fields[i].cell, value: '' }))
              }
              if (e.target.checked === true) setAggiornamento("Hai selezionato di non indicare la spesa annuale per le fonti energetiche")
            }}
          />
          <label htmlFor="non-la-conosco"
            style={{ marginLeft: '20px', cursor: 'pointer' }}
            // onKeyDown={e => {
            //   if (e.key === "Enter" || e.key === " ") {
            //       dispatch(
            //         set({ cell: cell, value: currentValue === 'No' ? 'Si' : 'No' })
            //       )
            //       for (let i = 0; i < fields.length; i++) {
            //         dispatch(set({ cell: fields[i].cell, value: undefined }))
            //       }
            //   }
            // }}
            // onClick={e => {
            //   dispatch(
            //     set({ cell: cell, value: currentValue === 'No' ? 'Si' : 'No' })
            //   )
            //   for (let i = 0; i < fields.length; i++) {
            //     dispatch(set({ cell: fields[i].cell, value: undefined }))
            //   }
            // }}
          >
            Non la conosco
          </label>
        </div>
      )}
    </div>
  )
}

export default PercentageComponent