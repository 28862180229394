
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import top from "../../icons/top.svg"
import bottom from "../../icons/bottom.svg"
import center from "../../icons/center.svg"
import fotovoltaico from "../../icons/icon/fotovoltaico.svg"
import batteria from "../../icons/icon/batteria.svg"


function SelectAtViewComponent({ name, cell, description, choices, extraClass }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const icons = [bottom, center, top, fotovoltaico, batteria]

    return (
        <div className={"selectatview-component mb-16 " + (data ? "filled " : "") + (extraClass ?? "")}>
            <div className="title">{name}
                {description && <div className="description">{description}</div>}
            </div>
            {choices && <div className="choice-box no-border" role="group">
                {choices.map((x, i) =>
                    <button key={i}
                        onClick={(e)  => {
                            dispatch(set({
                                cell: cell,
                                value: x.value
                            }))
                        }}
                        onKeyDown={(e) => { 
                            if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
                                e.preventDefault();
                                e.currentTarget.previousElementSibling?.focus();
                            } 
                            else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
                                e.preventDefault();
                                e.currentTarget.nextElementSibling?.focus();
                            }
                        }
                    }
                        className={"w-100 no-bold  no-bg text-left single-box d-flex align-items-center " + (currentValue === x.value ? "selected": "")} >
                        <input aria-label={x.text} 
                            tabIndex={-1}
                            type="radio" 
                            checked={data[cell] === x.text} 
                            className="choice" 
                            name={name} 
                            value={x.text}
                            id={x.text}
                           ></input>
                        <img src={icons[x.image]} className="icon-select" alt="" role='presentation' />
                        <label className="flex-grow-1 text-start" htmlFor={x.text}>{x.text}</label>
                    </button>
                )
                }
            </div>}
        </div>
    );
}

export default SelectAtViewComponent;