import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import logo from "../icons/storepos.svg"
import circle from "../icons/icon/finalcheck.svg"
import alert from "../icons/icon/Alert.svg"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../reducers/data'


function Thanks({ error, enelstore, retry, main }) {
    const dispatch = useDispatch()
    const data = useSelector(get)
    const messageRef = React.createRef();

    useEffect(()=> {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
            window.location.href = "/form"
        };

        window.enel_data = {
            page: {
                pageInfo: {
                    pageName: "Simulatore - Thank you page",
                    pageURL: "https://simulatore-risparmio-energia.enelxstore.com/thanks"
                }
            }
        };
        window?._satellite?.track("pageview")
        messageRef.current.focus()
    }, [])

    return (<div className="thanks d-flex flex-column">
          <h2 className="visually-hidden" aria-live="polite" aria-hidden="false" aria-relevant="all" role="banner" ref={messageRef} tabIndex={0}>
                {error ? 
                "Purtroppo al momento non abbiamo una soluzione adatta per le tue esigenze":
                "La valutazione è andata a buon fine, in pochi istanti ti mostreremo il tuo prospetto di risparmio"  }
            </h2>
        <Container fluid>
            <Row>
                <div>
                    <img src={logo} alt="vai alla home page di Enel X store" className='logo' />
                </div>
            </Row>
        </Container>
        <Container className="d-flex justify-content-center align-items-center flex-column">
            <main ref={main} className="text-center">
                {error ? <>
                    <img src={alert} alt="thanks" />
                    <h2>Siamo spiacenti</h2>
                    <p dangerouslySetInnerHTML={{  __html: error}}></p>
                </>
                :<>
                {data.Error && <p className="visually-hidden">{data.Error}</p>}
                  <img src={data.Error ? alert : circle} alt="thanks" />
                    <h2>{data.Error ? "Siamo spiacenti" : "Fantastico!"}</h2>
                    <p>{data.Error ? "La tua zona non è ancora coperta dal servizio." :<>Un nostro consulente tecnico ti contatterà al più presto. Riceverai una telefonata da uno dei <a style={{ color: "#5738FF" }} href="https://www.enelxstore.com/it/it/support-homepage/how-to-buy/telefonate-commerciali/numeri-telefonici-utilizzati-da-enel-x-per-offerte-commerciali" >seguenti numeri.</a>
        <br />
        Abbiamo inviato il risultato della simulazione alla email: <br />
        <b>{window.localStorage["Email"]}</b> <br />
        Controlla la tua casella di posta. <br />

        <a style={{ color: "#5738FF", fontWeight: 900}} href="https://enelxstore.com" target='_blank' rel="noreferrer">VAI SU ENELXSTORE.COM &gt;</a> <br />
        <a style={{ color: "#5738FF", fontWeight: 900}} href="https://enel.it" target='_blank' rel="noreferrer">VAI SU ENEL.IT &gt;</a></> }</p>
                </>}
                {retry === true &&  <a href="https://simulatore-risparmio-energia.enelxstore.com/"><button>CAMBIA COMUNE</button></a>}
            </main>
        </Container>
    </div>);
}

export default Thanks;