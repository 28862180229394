
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useState } from 'react';
import InfoButton from './InfoButtonComponent';
import SliderWithThumbComponent from './SliderWithThumbComponent';
import arrow from "../../icons/icon/chevron_right.svg"
import { Carousel, Modal, Container, Row, Col} from "react-bootstrap"
import closeIcon from '../../icons/icon/close.svg'

import b1 from '../../icons/icon/bolletta4.svg'
import FacSimileComponent from './FacSimileComponent';

function SliderComponent({ name, cell,sr, min, max, step, info, markers, canBeNull, disableIf, placeholder, description, unit, cellSiNo, extraClass, defaultValue, forceValue, facsimile,forceDisabled }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const [error, setError] = useState(data[cell] >= min && data[cell] <= max ? undefined : "Inserire un valore compreso tra " + min + " e " + max)
    const currentValue = data[cell]
    const [show, setShow] = React.useState(false)
    const disabled = (forceDisabled === true) ||( disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined))
    // debugger
    const checkAndSet = (cell, value) =>{
        // console.log(cell, value)
        // if (cell === "C13") {
        //     dispatch(set({
        //         cell: cell,
        //         value: value
        //     }))
        //     if (min && value < min) 
        //       setError("Il valore inserito non è consentito ed è stato sostituito con il valore massimo permesso.")
        //     else if (max && value > max) 
        //         setError("Il valore inserito non è consentito ed è stato sostituito con il valore minimo permesso.")
        //     else 
        //       setError(undefined)

        //     return;
        // }

        // console.log(cell, value)

        if (min && value < min) {
            setError("Inserire un valore compreso tra " + min + " e " + max)
            // value = min
            // if (data[cell] !== value) dispatch(set({
            //     cell: cell,
            //     value: value
            // }))

            // return
        } else if (max && value > max) {
            setError("Inserire un valore compreso tra " + min + " e " + max)
            // value = max 
            // if (data[cell] !== value) dispatch(set({
            //     cell: cell,
            //     value: value
            // }))

            // return
        }
        else setError(undefined)

        // if (cell !== "C13") {
        //     if (markers) {
        //         value = markers.reduce((prev, curr) => {
        //             return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
        //         });
        //     }
        //     else if (step !== undefined) {
        //         value = Math.round(value / step) * step
        //     }
        // }

        // setError(undefined)
        dispatch(set({
            cell: cell,
            value: value
        }))
    }

    const moveArrow = (e) => {
        const range = e.target;
        const value = range.value;
        const min = range.min || 0;
        const max = range.max || 100;
        let newVal = Number(((value - min) * 100) / (max - min));
        if (newVal < 0) newVal = 0;
        if (newVal > 100) newVal = 100;
        const thumb = range.closest(".sliderwiththumb").querySelector(".thumb");
        const fill = range.closest(".sliderwiththumb").querySelector(".fill");
        fill.style.width = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        if (newVal < 15)
            thumb.style.left = `calc(${newVal}% + (${8  - newVal * 0.15}px))`;    
        else if (newVal > 94)
            thumb.style.left = `calc(${newVal}% + (${-20 - newVal * 0.15}px))`;
        else    
            thumb.style.left = `calc(${newVal}% + (${0 - newVal * 0.15}px))`;
    }

    const dis = (data[cellSiNo] === "No" || (disableIf && data[disableIf.cell] == disableIf.value)) ? true : false
   
    return (<>
        <div className={"slider-component mb-16 d-flex flex-column  " + (extraClass ? extraClass : "") + ((data[cellSiNo] === "No" || (disabled ? " disa" : "" )))}>
            <div className="d-flex align-items-center mb-16 "><h2 className="pre mb-0">{name}</h2> {info && !dis && <InfoButton {...info} />}</div>
            {description &&<div className='description'>{description}</div> }
            {facsimile && <div className="facsimile"> <div
              className="fonti-facsimile"
              onClick={() => setShow(true)}
              onKeyDown={(e) => { if (e.key === "Enter" || e.key === " ") setShow(true) }}
              tabIndex={0}
            >
        VISUALIZZA FAC-SIMILE <img src={arrow} alt="" />
        <span className="visually-hidden">Scopri dove trovare questa informazione nella bolletta Enel</span>
      </div></div>}
            <div className="slider-container">
                <SliderWithThumbComponent 
                  forceValue={forceValue}
                  name={name}
                  cell={cell}
                  unit={unit}
                  min={min} 
                  max={max} 
                  step={step}
                  disabled={disabled}
                  error={error}
                  currentValue={currentValue}
                  moveArrow={moveArrow}   
                  setCurrentValue={(value) => checkAndSet(cell, value) 
                //     dispatch(set({
                //     cell: cell,
                //     value: value
                // }))
                } />
            </div>
        </div>
        {error && <div className="error-message" aria-errormessage={error}>{error}</div>}

        {cellSiNo && <div className="bool-nonso mb-16" >
            <input type="checkbox" id={"id" + cell}  
            aria-label={sr ?? "Non la conosco"}
            checked={data[cellSiNo] !== "Si"}
            onClick={(e) => 
                { 
                    dispatch(set({ cell: cellSiNo, value: e.currentTarget.checked ? "No" : "Si" }))
                    if (e.currentTarget.checked) {
                        checkAndSet(cell, defaultValue ?? min)
                        const slider = document.querySelector(".slider" + cell + " input")
                        if(slider){
                            slider.value = defaultValue ?? min
                            moveArrow({target: slider})
                        }
                    }
                    else {
                        dispatch(set({ cell: cell, value: defaultValue ?? min }))
                    }
                }}  />
            <label htmlFor={"id" + cell} style={{ cursor: "pointer"}}> Non la conosco </label>
            </div>}
            {facsimile && <FacSimileComponent facsimile={facsimile} show={show} close={() => setShow(false)} img={b1}  copy={"Il dato sulla potenza del tuo contatore è riportato nella seconda pagina della bolletta Enel relativa all'utenza elettrica. Puoi trovarlo alla voce \"Potenza contrattualmente impegnata\", all'interno del box informativo \"Dettagli tecnici della fornitura\" situato in alto a destra nella pagina. Il valore della potenza del contatore è espresso in kilowatt"} /> }
    </>
    );
}

export default SliderComponent;