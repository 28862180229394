import React, {useState} from 'react';
import {
    set, get
} from '../../reducers/data';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Carousel } from 'react-bootstrap';
import arrow from "../../icons/icon/chevron_right.svg"
import bolletta3 from '../../icons/bolletta3.svg'
import closeIcon from '../../icons/icon/close.svg'
import FacSimileComponent from './FacSimileComponent';





function LabelComponent({ name, extraClass,cell, facsimile, component }) {
    const [show, setShow] = useState(false)
    const data = useSelector(get);

    return (<>
        {component ? 
        <h2 className={"label-component " + (extraClass ? extraClass : "") } dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}>
        </h2>:<div className={"label-component " + (extraClass ? extraClass : "") } dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}>
        </div>}
        
         {facsimile && <div className="facsimile" tabIndex={0} > <div
         className="fonti-facsimile"
         onClick={() => setShow(true)}
       >
         VISUALIZZA FAC-SIMILE <img src={arrow} alt="arrow" />
         <span className="visually-hidden">Scopri dove trovare questa informazione nella bolletta Enel</span>
       </div>
       <FacSimileComponent 
       show={show} 
       close={() => setShow(false)} 
       copy={"Il dato sul consumo elettrico annuo è riportato nella seconda pagina della bolletta Enel. Puoi trovarlo alla voce \"Totale consumo\", nell'ultima colonna della tabelle informativa \"Consumo annuo\" situata nella colonna di sinistra della pagina. Il valore della spesa annua è espresso in kilowattora."} 
       img={bolletta3}
        n={3}
       />
       {/* <ModalPictures show={show} close={() => setShow(false)} /> */}
       </div>}</>)
}

export default LabelComponent;