import { createSlice } from '@reduxjs/toolkit';
import formTemplate from "../utils/form"

const fields = formTemplate.pages.reduce((prev, curr) => [...curr.items, ...prev] , [])

const numerical = ["slider", "number" ]

const obj = {...fields.filter(x => x.cell).reduce((prev, curr) => {
  if (numerical.includes(curr.type)){
    prev[curr.cell] = curr.min ?? 0
  }
  else if (curr.type === "optional"){
    prev[curr.cell] = false
  }
  else if (curr.type === "bool"){
    prev[curr.cell] = false
  }
  else if (curr.type === "choice"){
    prev[curr.cell] = ""
  }
  else {
    prev[curr.cell] = ""
  }
  return prev
}, {})}

const testData4 = {
    C78: 'No',
    C84: '',
    C12DaCambiare: '',
    PrestazionePannelli: '',
    C123132DaCambiare: false,
    PrestazioneCottura: '',
    C46: '1',
    NumeroClimatizzatori: false,
    // C42: '1',
    PrestazioneRiscaldamento: '',
    C31: 'No',
    C34: 'No',
    C35: '13450',
    C24: 'No',
    C25: 'Aria Propanata',
    C26: 'Pellet',
    C27: 'Aria Propanata',
    C28: 'No',
    C29: 'No',
    C61: false,
    D61: 3,
    Indirizzo: 'Via Silvio Montes, 1, Staranzano, Province of Gorizia, Italy',
    C11: 'Monofamiliare',
    PresenzaFotovoltaico: false,
    PresenzaBalcone: 'false',
    TipologiaBalconi: '',
    RistrutturatoUltimi10Anni: false,
    Coibentato: false,
    C10: '1961-1980 e non ristrutturata di recente',
    C36: 'E',
    C12: 'Piano intermedio',
    C13: 28,
    C16: 5,
    C15: 1,
    C17: 1,
    C18: 'No',
    C19: 'No',
    CondizionatoriRiscaldamento: false,
    C1111DacCambiare: '',
    C1111231DacCambiare: '',
    CAP: '34079',
    ViaPiazza: 'Via Silvio Montes',
    C14: 'Staranzano',
    country: 'Italy',
    NumeroCivico: '1',
    C33: '',
    F25: '',
    F26: '',
    EnelCoreModelId: 275,
    PreviousEnelCoreModelId: 0,
    CreateDate: '2023-05-16T14:46:34.6120405+00:00',
    ExecutionStart: null,
    Email: ' | TOTAL: 15790',
    F27: null,
    C30: null,
    C32: null,
    C41: 'Si',
    C45: 'Si',
    C47: '1',
    C48: 'Si',
    C53: 'Si',
    C54: 'No',
    C62: null,
    C66: null,
    C67: null,
    C68: null,
    C69: null,
    C70: null,
    C71: null,
    C79: null,
    C82: '3',
    D84: null,
    D41: 'LG ATMOSFERA Monosplit 9000 btu/h',
    E41: null,
    F41: null,
    D46: 'Ferroli Scaldacqua a pompa di calore EGEA 90 LT',
    E46: null,
    F46: null,
    D48: 'Whirlpool WLS5177NE (largh 77cm)',
    D53: null,
    Risc_Attuale: '0',
    Risc_Futura: '0',
    Risc_Risparmio: '0',
    Cott_Attuale: '163.02742',
    Cott_Futura: '36.373678940127',
    Cott_Risparmio: '-126.653741059873',
    AC_Attuale: '3.52',
    AC_Futura: '0.6674375998396',
    AC_Risparmio: '-3.1474375998396',
    Foto_Risparmio: '-10.4846174694375',
    Foto_Perc: '-0.3',
    PresenzaFotovoltaico: "No",
    SpesaAnnuaAttuale: null,
    SpesaAnnuaFutura: null,
    RisparmioAnnuo: null,
    CO2: null,
    Alberi: null,
    Investimento: null,
    RecuperoTotale: null,
    RisparmioPerc: 0,
}

const testData3 = {
    "EnelCoreModelId": 102,
    "CreateDate": "2023-04-27T14:18:36.5352645+02:00",
    "ExecutionStart": null,
    "Email": null,
    "ProjectUrl": null,
    "C10": "1919-1945 e non ristrutturata di recente",
    "C11": "Monofamiliare",
    "C12": "",
    "C13": "30",
    "C14": "Staranzano",
    "C15": "1",
    "C16": "1",
    "C17": "12",
    "C18": "Si",
    "C19": "true",
    "C24": "true",
    "C25": "",
    "F25": null,
    "C26": "Aria Propanata",
    "C27": "GPL",
    "C28": "true",
    "C29": "true",
    "C30": null,
    "C31": "Si",
    "C32": null,
    "C33": "",
    "C34": "true",
    "C35": "1250",
    "C36": "A",
    "C41": null,
    "C45": null,
    "C46": null,
    "C47": null,
    "C48": null,
    "C53": null,
    "C54": null,
    "D61": "10",
    "C62": null,
    "C66": null,
    "C67": null,
    "C68": null,
    "C69": null,
    "C70": null,
    "C71": null,
    "C78": "false",
    "C79": null,
    "C84": "",
    "D84": null,
    "Risc_Attuale": "1000",
    "Risc_Futura": "411.378028715443",
    "Risc_Risparmio": "-411.378028715443",
    "Cott_Attuale": "300",
    "Cott_Futura": "142.401588243994",
    "Cott_Risparmio": "-142.401588243994",
    "AC_Attuale": "200",
    "AC_Futura": "199.684586782343",
    "AC_Risparmio": "-199.684586782343",
    "Foto_Risparmio": null

}

const testData2 =  {
  C78: 'Non so',
  D84: 'No',
  C12DaCambiare: '',
  PrestazionePannelli: '',
  D48: 'Piano cottura a induzione Smeg SI2741D (larghezza 75 cm)',
  C46: '1',
  NumeroClimatizzatori: false,
  C42: null,
  PrestazioneRiscaldamento: '',
  C31: 'No',
  C34: 'No',
  C35: '13450',
  C24: 'No',
  C25: 'Gasolio',
  C26: 'Gasolio',
  C27: 'Aria Propanata',
  C28: 'No',
  C29: 'No',
  C61: false,
  D61: '3',
  Indirizzo: 'Via Silvio Montes, 1, Staranzano, Province of Gorizia, Italy',
  C11: 'Monofamiliare',
  PresenzaFotovoltaico: 'No',
  PresenzaBalcone: 'No',
  TipologiaBalconi: '',
  RistrutturatoUltimi10Anni: false,
  Coibentato: false,
  C10: '1946-1960 e non ristrutturata di recente',
  C36: 'B',
  C12: '',
  C13: '90',
  C16: '1',
  C15: '1',
  C17: '10',
  C18: 'No',
  C19: 'No',
  CondizionatoriRiscaldamento: false,
  CAP: '34079',
  ViaPiazza: 'Via Silvio Montes',
  C14: 'Staranzano',
  country: 'Italy',
  NumeroCivico: '1',
  C33: null,
  F25: null,
  F27: null,
  EnelCoreModelId: 386,
  PreviousEnelCoreModelId: 0,
  CreateDate: '2023-05-20T14:31:19.258861+00:00',
  ExecutionStart: null,
  Email: ' | TOTAL: 17048',
  ProjectUrl: 'https://greenovation.blob.core.windows.net/enelcore/386.pdf',
  F26: null,
  C30: null,
  C32: null,
  C41: 'Si',
  C45: 'Si',
  C47: null,
  C48: 'Si',
  C53: 'Si',
  C54: 'No',
  C62: null,
  C66: null,
  C67: null,
  C68: null,
  C69: null,
  C70: null,
  C71: null,
  C79: null,
  C82: '3',
  C84: 'No',
  D41: 'LG ATMOSFERA Monosplit 9000 btu/h',
  E41: null,
  F41: null,
  D46: 'Ferroli Scaldacqua a pompa di calore EGEA 90 LT',
  E46: null,
  F46: null,
  D53: 'Sun Classic Medium 3 KWp',
  Risc_Attuale: '1114.58019890511',
  Risc_Futura: '958.903099930134',
  Risc_Risparmio: '-155.677098974975',
  Cott_Attuale: '149.68162',
  Cott_Futura: '44.691344705647',
  Cott_Risparmio: '-104.990275294353',
  AC_Attuale: '75.2703510948905',
  AC_Futura: '53.6529865290538',
  AC_Risparmio: '-21.6173645658367',
  Foto_Risparmio: '-155.840966684934',
  Foto_Perc: '-0.3',
  SpesaAnnuaAttuale: null,
  SpesaAnnuaFutura: null,
  RisparmioAnnuo: null,
  CO2: null,
  Alberi: null,
  Investimento: null,
  RecuperoTotale: null,
  RisparmioPerc: 0,
  PrestazioneCottura: null
}

const testData = {
  C13: '28',
  C16: 1,
  C15: 1,
  C17: 12,
  C34: false,
  C61: false,
  C48Test: false,
  C78: "Non so"
  // D48_val: "59.2cm",
    // C24: false,
    // C25: 'GPL',
    // C26: 'GPL',
    // C27: 'Elettrico',
    // C28: false,
    // C29: false,
    // ViaPiazza: 'via montes',
    // NumeroCivico: '123',
    // C14: 'san pier d\'isonzo',
    // CAP: '34070',
    // Nazione: 'Italia',
    // C11: 'Monofamiliare',
    // C123: true,
    // Balconi: true,
    // C111: 'Ringhiera',
    // Ristrutturato: true,
    // C1234: true,
    // C10: '1919-1945 e non ristrutturata di recente',
    // C36: 'A+',
    // C12: '2',
    // C13: '280',
    // CDaCambiare: '3',
    // C17: '12',
    // CDaCambiare5: false,
    // CDaCambiare6: true,
    // CDaCambiare7: true,
    // TipologiaBalconi: "Ringhiera"
}

const testData5 =  {
  C78: 'No',
  C48Test: false,
  // D84: 'bestia',
  C12DaCambiare: '',
  PrestazionePennelli: '',
  D48: 'Whirlpool WLS5177NE (largh 77cm)',
  C46: '1',
  NumeroClimatizzatori: 'No',
  C42: null,
  PrestazioneRiscaldamento: '',
  C31: 'No',
  C34: 'No',
  C35: '13450',
  C24: 'No',
  C25: 'Aria Propanata',
  C26: 'Pellet',
  C27: 'Aria Propanata',
  C28: 'No',
  C29: 'No',
  D61: '3',
  Indirizzo: 'Via Silvio Montes, 1, Staranzano, Province of Gorizia, Italy',
  C11: 'Monofamiliare',
  PresenzaFotovoltaico: 'No',
  PresenzaBalcone: 'false',
  TipologiaBalconi: '',
  RistrutturatoUltimi10Anni: false,
  Coibentato: false,
  C10: '1961-1980 e non ristrutturata di recente',
  C36: 'E',
  C12: 'Piano intermedio',
  C13: null,
  C16: '5',
  C15: '1',
  C17: '1',
  C18: 'Si',
  C19: 'No',
  CondizionatoriRiscaldamento: false,
  C84: '',
  C123132DaCambiare: false,
  PrestazioneCottura: '',
  C61: false,
  C1111DacCambiare: '',
  C1111231DacCambiare: '',
  CAP: '34079',
  ViaPiazza: 'Via Silvio Montes',
  C14: 'Staranzano',
  country: 'Italy',
  NumeroCivico: '1',
  C33: '',
  F25: '',
  F26: '',
  EnelCoreModelId: 608,
  PreviousEnelCoreModelId: 0,
  CreateDate: '2023-06-05T14:36:32.0787685+00:00',
  ExecutionStart: null,
  Email: ' | TOTAL: 15790',
  F27: null,
  C30: null,
  C32: null,
  C41: 'Si',
  C45: 'Si',
  C47: '1',
  C48: 'Si',
  C53: 'Si',
  C54: 'No',
  C62: null,
  C66: null,
  C67: null,
  C68: null,
  C69: null,
  C70: null,
  C71: null,
  C79: null,
  C82: '3',
  D41: 'LG ATMOSFERA Monosplit 9000 btu/h',
  E41: null,
  F41: null,
  D46: 'Ferroli Scaldacqua a pompa di calore EGEA 90 LT',
  E46: null,
  F46: null,
  D53: null,
  Risc_Attuale: '0',
  Risc_Futura: '0',
  Risc_Risparmio: '0',
  Cott_Attuale: '163.02742',
  Cott_Futura: '36.373678940127',
  Cott_Risparmio: '-126.653741059873',
  AC_Attuale: '3.52',
  AC_Futura: '0.6674375998396',
  AC_Risparmio: '-3.1474375998396',
  Foto_Risparmio: '-10.4846174694375',
  Foto_Perc: '-0.3',
  SpesaAnnuaAttuale: '729€',
  SpesaAnnuaFutura: '189 €',
  RisparmioAnnuo: '-540',
  CO2: '226 kg',
  Alberi: '12 alberi',
  Investimento: '4,436 €',
  RecuperoTotale: '9',
  RisparmioPerc: -0.7407346459283083,
  products: [
    {
      name: 'Clima',
      modello: 'LG ATMOSFERA Monosplit 9000 btu/h',
      costo: '1,469',
      scontato: '514',
      ecobonus: '65%',
      link: 'https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/mono-split/clima-atmosfera-monosplit-9000-btu-h',
      qty: 1
    },
    // {
    //   name: 'ScaldAcqua',
    //   modello: 'Ferroli Scaldacqua a pompa di calore EGEA 90 LT',
    //   costo: '1,709',
    //   scontato: '598',
    //   ecobonus: '65%',
    //   link: '',
    //   qty: 1
    // },
    {
      name: 'Induzione',
      modello: 'Piano cottura a induzione Smeg SI2641D  (larghezza 60 cm)',
      costo: '329',
      scontato: '329',
      ecobonus: '0%',
      link: 'https://www.enelxstore.com/it/it/prodotti/elettrodomestici-e-tecnologia/grandi-elettrodomestici/piani-a-induzione/piano-cottura-a-induzione-smeg-si2641d-4-zone',
      qty: 1
    },
    // {
    //   name: 'Fotovoltaico',
    //   modello: 'Sun Classic Medium 3 KWp',
    //   costo: '5,990',
    //   scontato: '2,995',
    //   ecobonus: '50%',
    //   link: null,
    //   qty: 1
    // }
  ],
  ProjectUrl: 'https://greenovation.blob.core.windows.net/enelcore/608.pdf',
  pageIndex: 100
}

const testData6 =  {
  C78: 'Non so',
  D84: '',
  C12DaCambiare: '',
  PrestazionePennelli: 'Top di gamma €€€',
  D48_val: false,
  C46: '2',
  NumeroClimatizzatori: 'No',
  C42: null,
  PrestazioneRiscaldamento: 'Top di gamma €€€',
  C31: 'No',
  C34: 'No',
  C35: '',
  C24: 'No',
  C25: 'GPL',
  C26: 'GPL',
  C27: 'GPL',
  C28: 'No',
  C29: 'No',
  D61: '3',
  Indirizzo: 'Via Roma, 1, 38033 Cavalese TN, Italy',
  C11: 'Monofamiliare',
  PresenzaFotovoltaico: 'No',
  PresenzaBalcone: 'No',
  TipologiaBalconi: '',
  RistrutturatoUltimi10Anni: false,
  Coibentato: false,
  C10: '1946-1960 e non ristrutturata di recente',
  C36: 'F',
  C12: '',
  C13: '90',
  C16: '5',
  C15: '4',
  C17: '12',
  C18: 'Si',
  C19: 'No',
  CondizionatoriRiscaldamento: false,
  C61: 'No',
  C48Test: false,
  CAP: '38033',
  ViaPiazza: 'Via Roma',
  C14: 'Cavalese',
  country: 'Italy',
  NumeroCivico: '1',
  FormattedAddress: 'Via Roma, 1, 38033 Cavalese TN, Italy',
  pageIndex: 100,
  F25: '0',
  C33: null,
  EnelCoreModelId: 1361,
  PreviousEnelCoreModelId: 0,
  CreateDate: '2023-07-28T15:54:35.5170295+00:00',
  ExecutionStart: null,
  Email: 'diego.banovaz.testing287555@gmail.com',
  F26: '0',
  F27: '0',
  C30: null,
  C32: null,
  C41: false,
  C45: false,
  C47: null,
  C48: false,
  C53: false,
  C54: null,
  C62: null,
  C66: null,
  C67: null,
  C68: null,
  C69: null,
  C70: null,
  C71: null,
  C79: null,
  C82: '6',
  C84: 'No',
  D41: 'LG ATMOSFERA Dualsplit 9+12 btu/h',
  E41: 'LG ATMOSFERA Trialsplit 9+9+12 btu/h',
  F41: null,
  D46: null,
  E46: null,
  F46: null,
  D48: 'No',
  D53: 'Enel X Sun Medium 3 kWp',
  Risc_Attuale: '3024.06484931507',
  Risc_Futura: '1015.55914093822',
  Risc_Risparmio: '-2009',
  Cott_Attuale: null,
  Cott_Futura: null,
  Cott_Risparmio: null,
  AC_Attuale: null,
  AC_Futura: null,
  AC_Risparmio: null,
  Foto_Risparmio: '-307',
  Foto_Perc: '-0.215154184677921',
  SpesaAnnuaAttuale: '4.049€',
  SpesaAnnuaFutura: '559 €',
  RisparmioAnnuo: '-3490',
  CO2: '4.558 kg',
  Alberi: '253 alberi',
  Investimento: '18.618 €',
  RecuperoTotale: '6',
  RisparmioPerc: 0.8619581582778276,
  PrestazioneCottura: null,
  CRMID: null,
  products: [
    {
      name: 'Clima',
      modello: 'LG ATMOSFERA Dualsplit 9+12 btu/h',
      costo: '',
      scontato: '2.629',
      ecobonus: '',
      link: 'https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/atmosfera-dualsplit-9-12-btu-h',
      qty: 1
    },
    {
      name: 'Clima',
      modello: 'LG ATMOSFERA Trialsplit 9+9+12 btu/h',
      costo: '',
      scontato: '3.499',
      ecobonus: '',
      link: 'https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/atmosfera-trialsplit-9-9-12-btu-h',
      qty: 1
    },
    {
      name: 'Fotovoltaico',
      modello: 'Enel X Sun Classic XLarge 6kWp',
      costo: '',
      scontato: '12.490',
      ecobonus: '',
      link: null,
      qty: 1
    }
  ],
  Nome: 'Diego',
  Cognome: 'Testing',
  Telefono: '3880482274',
  Privacy: true,
  Capcha: true
}

const testData7 = {
    C78: 'Non so',
    D84: '',
    C12DaCambiare: '',
    PrestazionePennelli: '',
    D48_val: false,
    C46: '1',
    NumeroClimatizzatori: '4',
    C42: '2',
    PrestazioneRiscaldamento: 'Miglior rapporto qualità/prezzo €€',
    C31: 'No',
    C34: 'No',
    C35: '',
    C24: 'No',
    C25: 'Gasolio',
    C26: 'Legna secca',
    C27: 'Aria Propanata',
    C28: 'No',
    C29: 'No',
    D61: '3',
    Indirizzo: '',
    C11: 'Monofamiliare',
    PresenzaFotovoltaico: 'No',
    PresenzaBalcone: 'No',
    TipologiaBalconi: '',
    RistrutturatoUltimi10Anni: false,
    Coibentato: false,
    C10: 'Prima del 1919 e non ristrutturata di recente',
    C36: 'E',
    C12: 'Piano intermedio',
    C13: '90',
    C16: '3',
    C15: '1',
    C17: '12',
    C18: 'Si',
    C19: 'No',
    CondizionatoriRiscaldamento: false,
    C61: 'No',
    C48Test: false,
    C14: 'San Pier d\'Isonzo',
    CAP: '34070',
    pageIndex: 4,
    F25: '0',
    F26: '0',
    F27: '0',
    C33: null,
    EnelCoreModelId: 5817,
    PreviousEnelCoreModelId: 0,
    CreateDate: '2024-01-18T11:16:57.0524511+00:00',
    ExecutionStart: null,
    Email: null,
    C30: null,
    C32: null,
    C41: false,
    C45: false,
    C47: null,
    C48: false,
    C53: false,
    C54: 'No',
    C62: null,
    C66: null,
    C67: null,
    C68: null,
    C69: null,
    C70: null,
    C71: null,
    C79: null,
    C82: '3',
    C84: 'No',
    D41: 'LG ATMOSFERA Trialsplit 9+9+12 btu/h',
    E41: null,
    F41: null,
    D46: null,
    E46: null,
    F46: null,
    D48: 'No',
    D53: 'Enel X Sun Plug&Play',
    Risc_Attuale: '1665.49995',
    Risc_Futura: '1308.53022953259',
    Risc_Risparmio: '-357',
    Cott_Attuale: null,
    Cott_Futura: null,
    Cott_Risparmio: null,
    AC_Attuale: null,
    AC_Futura: null,
    AC_Risparmio: null,
    Foto_Risparmio: '-77',
    Foto_Perc: '-0.0945802778966917',
    SpesaAnnuaAttuale: '2.225€',
    SpesaAnnuaFutura: '1.047 €',
    RisparmioAnnuo: '-1178',
    CO2: '3.770 kg',
    Alberi: '209 alberi',
    Investimento: '3.477 €',
    RecuperoTotale: '3',
    RisparmioPerc: 0.5295124634831461,
    PrestazioneCottura: null,
    CRMID: null,
    extraco2: 'La resa di Enel X Sun Plug&Play è stata ricavata dal <a href="https://re.jrc.ec.europa.eu/pvg_tools/it" target="_blank">tool online PVGIS</a>, considerando l\'orientamento del pannello verso sud-est, assenza di ombreggiatura e latitudine di Milano',
    ModificaConfigurazione: true,
    products: [
      {
        name: 'Clima',
        modello: 'LG Libero SMART Dualsplit 9+12 btu/h',
        costo: '',
        scontato: '2.938',
        ecobonus: '',
        link: 'https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/lg-libero-smart-9000---12000-btu-dualsplit',
        qty: 2
      },
      {
        name: 'Fotovoltaico',
        modello: 'Enel X Sun Plug&Play',
        costo: '',
        scontato: '539',
        ecobonus: '',
        link: null,
        qty: 1
      }
    ]
}

const initialState = {
  ...obj,
  ...testData
};


export const counterSlice = createSlice({
  name: 'data',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set: (state, action) => {
      // console.log(action)
      if (action.payload.cell === 'ProjectUrl' && (!action.payload || !action.payload.value || action.payload.value === '')) 
          return;        
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state[action.payload.cell] = action.payload.value;
      return state
    },
    reset: (state, action) => {
      state = { ...testData }
      return state
    }
  },
});

export const { set, reset } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;
export const get = (state) => state.data

export default counterSlice.reducer;
