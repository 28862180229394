import optionsAi from "./climaai.json"


const testRendering = (item, data) =>{
    //props are in OR
    if (item.notCondo === true){
        if (data["C11"] !=="Monofamiliare" && data["C11"] !=="Bifamiliare") return false
    }
    if (!item.conditionalRendering) return true;
    for (let i = 0; i < item.conditionalRendering.length; i++) {
        const element = item.conditionalRendering[i];

        if (element.operator === "notin" && !element.value.includes(data[element.cell])) return false;
        if (element.operator === "notequal" && data[element.cell] !== element.value) return true;
        if (element.operator === "equal" && data[element.cell] === element.value) return true;
    }
    return false
}

const sendFoto = (data) =>{
    const non = (val) => {
        return val === undefined || val === "No" || val === false
    }

    return ((data["C11"] === "Monofamiliare" || data["C11"] === "Bifamiliare")
            && non(data["C45"]) && non(data["C41"]) 
            && non(data["C48"]) && (data["C53"] === true || data["C53"] === "Si"))
}

// const checkIfProjectIsComplete = async (id) => { 
//     const response = await fetch(`https://greenovationdashboard.azurewebsites.net/api/enelcore/?id=${id}`, {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json"
//         }
//     })
//     if (!response.ok) 
//         throw new Error("Error downloading project", response.status)

//     const json = await response.json()
//     return json
// }

// const createProject = async (data) => {
//     const dataClone = { ...data }
//     for (let value in dataClone) { 
//         if (dataClone[value] === true) dataClone[value] = "Si"
//         if (dataClone[value] === false) dataClone[value] = "No"
//         if (dataClone[value] === null) delete dataClone[value]
//     }

//     const response = await fetch("https://greenovationdashboard.azurewebsites.net/api/enelcore", {
//         method: "POST",
//         body: JSON.stringify(dataClone),
//         headers: {
//             "Content-Type": "application/json"
//         }
//     })
//     if (!response.ok) 
//         throw new Error("Error creating project", response.status)

//     return await response.json()
// }

const checkCAP = async (data) => {
    return true;
}

const sendProject = async (data) => {
    const dataClone = { ...data }
    for (let value in dataClone) { 
        if (dataClone[value] === true) dataClone[value] = "Si"
        if (dataClone[value] === false) dataClone[value] = "No"
        if (dataClone[value] === null) delete dataClone[value]
    }

    const response = await fetch("https://greenovationdashboard.azurewebsites.net/api/enelcore", {
        method: "POST",
        body: JSON.stringify(dataClone),
        headers: {
            "Content-Type": "application/json"
        }
    })
    if (!response.ok) 
        throw new Error("Error creating project", response.status)

    return await response.json()
}

const sendProjectFinal = async (data) => {
    const dataClone = { ...data }
    
    // console.log(data["NumeroClimatizzatori"])
    if (data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === false || data["NumeroClimatizzatori"] === "No") 
        dataClone["NumeroClimatizzatori"] = Math.min(parseInt(data["C16"]), 9)

    if (dataClone["C42"] === null || dataClone["C42"] === undefined){
        dataClone["C42"] = optionsAi.find(item => item.N == dataClone["NumeroClimatizzatori"])?.macchine
    } 
    else {
        dataClone["C42"] = optionsAi.find(item => item.name === dataClone["C42"])?.macchine
    }
    dataClone["D48"] = dataClone["D48_val"]
    // debugger
    for (let value in dataClone) { 
        if (dataClone[value] === true) dataClone[value] = "Si"
        if (dataClone[value] === false) dataClone[value] = "No"
        if (dataClone[value] === null) delete dataClone[value]
    }

    dataClone.PreviousEnelCoreModelId = dataClone.EnelCoreModelId
    delete dataClone.EnelCoreModelId

    const response = await fetch("https://greenovationdashboard.azurewebsites.net/api/enelcorefinal", {
        method: "POST",
        body: JSON.stringify(dataClone),
        headers: {
            "Content-Type": "application/json"
        }
    })
    if (!response.ok) 
        throw new Error("Error creating project", response.status)

    return await response.json()
}

const sendLead = async (data) => {
    // data.prd = process.env.REACT_APP_ENV
    const response = await fetch("https://greenovationdashboard.azurewebsites.net/api/enelcorelead", {
        method: "POST",
        body: JSON.stringify({...data, prd: process.env.REACT_APP_ENV}),
        headers: {
            "Content-Type": "application/json"
        }
    })
    if (!response.ok) 
        throw new Error("Error creating project", response.status)

    return await response.json()
}


const exportedFunctions =  {
    sendProjectFinal,
    testRendering,
    checkCAP,
    // checkIfProjectIsComplete,
    sendProject,
    sendLead,
    sendFoto
}

export default exportedFunctions