
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import infoIcon from "../../icons/icon/open-link.svg"


function AcceptComponent({ name, cell, extraClass, ariaLabel, description, href, disableIf, component, externalLink }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    
    const currentValue = data[cell]
    const disabled = disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined)

    return (<>
        <div className={"switch-component accept-final d-flex flex-row align-items-center justify-content-between " + (data ? "filled " : "") + (extraClass ?? "") + (description === undefined ? " mb-16 " : "") }>
            <div className="d-flex align-items-center">
                <input type="checkbox" 
                    aria-checked={currentValue}
                    
                    id={"id-"+cell}
                    checked={currentValue === true ? true : false} onClick={(e) => dispatch(set({
                    cell: cell,
                    value: e.currentTarget.checked
                }))} />
                <div style={{ marginRight: 16, borderRight: "1px solid #C2CDDD", paddingRight: 16, width:0, height:32}}></div>
                <label htmlFor={"id-" + cell} style={{color: "#313B49", fontSize: "1rem", lineHeight: "1rem", paddingRight: "0px"}} className={"switch-description " + (currentValue ? "bold" : "") }> {name} </label>
            </div>
            <a href={href} target="_blank" rel="noreferrer" aria-label={ariaLabel} >    
                <img src={infoIcon} alt="" className="me-3" />
            </a>
           
        </div>
        </>
    );
}

export default AcceptComponent;