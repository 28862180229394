import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate} from 'react-router-dom';
import ria from '../icons/ria.png';
import info from "../icons/icon/info.svg";
import Header from './form/Header';
import NextButtonComponent from './form/NextButtonComponent';
import InfoModal from './form/InfoModalComponent';
import InfoButton from './form/InfoButtonComponent';
import AutoComplete from "./form/AddressCompleteComponent"


function Landing() {
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const main = useRef(null)
    
    useEffect(()=> {
        window.enel_data = {
            page: {
                pageInfo: {
                    pageName: "Simulatore - Landing Page",
                    pageURL: "https://simulatore-risparmio-energia.enelxstore.com"
                }
            }
        };
        window?._satellite?.track("pageview")
    }, [])
    if (shouldRedirect)
        return <Navigate to="/form" />

    return (   <><div id="landing" className="d-lg-flex align-items-center justify-content-center">
        <Header 
                main={main}
                className={"col-lg-5"}
                title={"Calcola i consumi energetici della tua casa"} 
                subtitle={"Ottieni una stima del potenziale risparmio sui consumi energetici della tua casa in soli 5 minuti"} />
        <main className="landing-box col-lg-7" ref={main} tabIndex={0}>
            <div className="landing-text">
                <div className='d-lg-none'>Ottieni una stima del potenziale risparmio sui consumi energetici della tua casa in circa <b>5 minuti.</b></div>

                <div className="list-item-title d-flex">
                    {/* <div></div> */}
                    <div>1 Prepara le informazioni utili</div>
                </div>
                <div className="small-text mbl48">
                    Ti saranno richieste informazioni relative alla tua casa e alla spesa di luce e gas.
                </div>

                <div className="list-item-title d-flex">
                    {/* <div>2</div> */}
                    <div>2 Inserisci le informazioni richieste</div>
                </div>
                <div className="small-text mbl48">
                    Compila tutti i dati per aiutarci a elaborare la tua simulazione.
                </div>

                <div className="list-item-title d-flex">
                    {/* <div>3</div> */}
                    <div className="simulazione3"><div >3 Ottieni la tua simulazione</div> 
                        </div>
                </div>
                <div className="small-text">
                    Avrai una simulazione del risparmio che puoi ottenere con un modello di calcolo validato.
                </div>

                <img className="mbl-24" src={ria} id="ria" alt="Organismo di certificazione RINA"/>
                    <InfoButton 
                        title={"Cos'è RINA?"}
                        altTitle={"Apri una finestra con più informazioni sull'organismo di certificazione RINA"}
                        subtitle={'RINA è un organismo di certificazione di terza parte che fornisce una garanzia di indipendenza e trasparenza. Per maggiori informazioni visita il sito:  <br /> <br class="d-none d-lg-block" /> <a aria-label="apri il link sulla certificazione rina in una nuova finestra" href="https://www.rina.org/it/business/certification" target="_blank">www.rina.org/it/business/certification</a>'} />
                
                <p className="small-text d">
                    Alcuni dati sono precompilati per velocizzare la creazione della tua simulazione. Se preferisci, puoi comunque modificarli. <br />
                </p>
                <p className="smaller-text">
                    I tuoi dati verranno utilizzati solo ai fini della simulazione e possono anche essere indicativi. Tuttavia per ottenere una simulazione più vicina possibile alla realtà ti raccomandiamo di inserire informazioni quanto più precise possibili.
                </p>

                {/* <Link to="/form"> */}
                    <NextButtonComponent
                        tabIndex={-1}
                        onlyContinue={false}
                        buttonEnabled={false}
                        text={"INIZIA"}
                        extraClass={"pr-16"}
                        onClick={() => { setShouldRedirect(true) }}
                    />
                {/* </Link> */}
            </div>

            {/* <div className="landing-sub"> */}
              
            {/* </div> */}

          
        </main>

       

     
     <div id="cookie-bar-enel">
<div id="consent_blackbar"></div>

    </div>
 
    <div id="teconsent" ></div>
    </div> </>);
}

export default Landing;