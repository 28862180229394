import React, { useState } from 'react'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../../reducers/data'

function Prodotto ({ item, key, index, selected }) {
  if (!item.modello || !item.scontato){
    console.log(item)
    return <></>
  }

  let imgUrl =""
  if (item.name === "ScaldAcqua")
      imgUrl = "/products/scaldacqua.png"
  else if (item.name === "Fotovoltaico")
      imgUrl = "/products/fotovoltaico.png"
  else if (item.name === "Clima")
    imgUrl = "/products/clima.png"
  else if (item.name === "Induzione")
    imgUrl = "/products/piano-induzione.png"

  return (
    <Col key={index}>
      <div className='product'>
          <div className='top d-flex justify-content-between'>
            <div className="img-container">
                <img src={imgUrl} alt={"icona " + item.title} role="presentation" />
            </div>
            <div className="product-container" style={{ flexGrow: 1 }}>
              <span className='tag'>{selected.replace("Acqua", "acqua")}</span>
              <div className='product-title'>
                {item.link && item.link.length > 1 ? 
                <a target='_blank' rel='noreferrer'>{item.modello} </a>
                 : 
                 <>{item.modello}</>
                 }
              </div>
              <div className='d-flex justify-content-between light'>
                {item.modello.indexOf("Plug&Play") > -1 ? 
                <div>Consegna bordo strada inclusa</div>
                : <div>Installazione inclusa</div>}
                <div className='qty'>Quantità: {item.qty}</div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-16'>
            <div className='light'>
              Totale <br />
              (IVA inclusa)
            </div>
            <div className='price'>
              <div className='final'>{item.scontato} €</div>
              <div className='sub'>
                {/* <span className='green'>Ecobonus {item.ecobonus}&nbsp;</span> */}
                {/* <span className='striked'>{item.costo.replace(",", ".")} €</span> */}
              </div>
            </div>
          </div>
      </div>
    </Col>
  )
}

const txts = {
  "Clima":"Per migliorare il riscaldamento della tua casa ti suggeriamo di usare un climatizzatore a pompa di calore:",
  "ScaldAcqua":"Per migliorare la produzione di acqua calda della tua casa ti suggeriamo di usare uno scalda acqua a pompa di calore:",
  "Induzione":"Per migliorare la cottura cibi della tua casa ti suggeriamo di usare una piastra ad induzione:",
  "Fotovoltaico":"Per migliorare l'efficienza energetica della tua casa ti suggeriamo di usare un sistema di autoproduzione:"
}

function FinalHeaderComponent () {
  const data = useSelector(get)
  const prodotti = data.products.reduce((acc, item) => {
    if (acc[item.name]) {
      acc[item.name].push(item)
    } else {
      acc[item.name] = [item]
    }
    return acc
  }, {})
  let startingCategory
  Object.keys(prodotti).forEach(key => {
    if (prodotti[key].length > 0) {
      if(startingCategory === undefined)
        startingCategory = key
      return
    }
  })
  const [selected, setSelected] = useState(startingCategory) 
  const copy = txts[selected]
  return (
    <div className='header'>
      <Container className='final-header'>
        <Row>
          {/* <Col md={6} className='left-side'>
            <img src={logo} alt='enelx logo' />
            <div>Ecco la soluzione su misura per te</div>
          </Col> */}
          <Col md={12} className='right-side'>
            
            <div className='subtitle'>Prodotti</div>
            {Object.keys(prodotti).length > 1 &&
            <ButtonGroup className='buttons'>
              {Object.keys(prodotti).map((key, index) => (
                <Button
                  key={index}
                  variant='outline-primary'
                  className={key === selected ? 'active' : ''}
                  onClick={() => setSelected(key)}
                >
                  {key.replace("Acqua", "acqua").replace("Clima", "Riscaldamento").replace("Induzione", "Cottura Cibi")}
                </Button>
              ))}
            </ButtonGroup>
            }
            {copy && <div id="final-message" className='copy'>{copy}</div>}
            <Row className='prodotti'>
              {prodotti[selected].map((item, index) => (
                <Prodotto
                  key={index}
                  item={item}
                  index={index}
                  selected={selected}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FinalHeaderComponent
