import React from 'react';
import { useState,useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import logo from "../../icons/storepos.svg"
import circle from "../../icons/icon/finalthanks.svg"
import { get } from '../../reducers/data';
import { useSelector } from 'react-redux';

function WaitAndThanks({ loading, fs, title, main}) {
    const [index, setIndex] = useState(0)
    const messageRef = React.createRef();
    const data = useSelector(get);

    useEffect(()=>{
        messageRef.current.focus()
    })

    return (<div id="thanks" className={"d-flex flex-column "+ (fs === true ? "fs" : "") }>
        <div className="header">
            <a href="https://enelxstore.com"><img src={logo} height={32} alt="vai alla home page di Enel X store"/></a>
        </div>
        <div aria-live="polite" className="visually-hidden" aria-relevant="all" tabIndex={0} ref={messageRef} role="banner">
            {loading ? "Attendi pochi secondi il simulatore sta stimando il tuo risparmio": "Fantastico La valutazione è andata a buon fine, in pochi istanti ti mostreremo il tuo prospetto di risparmio" } 
        </div>
        { loading ? <Container className="d-flex flex-column justify-content-center align-items-center">
        <main ref={main}>
            <Spinner id="spinner" className='' size={"bg"} />
          
            <div className="title">
                 {title || "Attendi ancora pochi secondi" }
            </div>
            </main>
        </Container> :
        <Container className="d-flex flex-column justify-content-center align-items-center">
        <img src={circle} height="66px" alt="loading spinner" />
        <div className="title">
            Fantastico!
        </div>
        <div className="subtitle">
        Abbiamo calcolato il risparmio che puoi ottenere con soluzioni più efficienti.
        </div>
  
    </Container> }

    
    </div>);
}

export default WaitAndThanks;