
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import { current } from '@reduxjs/toolkit';
// import { parse } from 'dotenv';

function TextComponent({ name, cell, type, pattern }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]

    return (
        <div className={"text-component d-flex flex-column input-border justify-content-center " + (data ? "filled" : "") }>
            {currentValue && <div className="fieldname">{name}</div>}
            <input
                className=''
                type={ type ?? "text" }
                placeholder={name}
                pattern={pattern || undefined}
                value={currentValue} onChange={(e) => {
                    if(cell === "Telefono")
                    {
                        const v = e.target.value;
                        if (v.length > 0 && isNaN(v[v.length - 1]) && v !== "+")
                            return;

                        dispatch(set({
                                cell: cell,
                                value: e.target.value
                        }))
                    }
                    else
                        dispatch(set({
                            cell: cell,
                            value: e.target.value
                        }))
                }}
            />
        </div>
    );
}

export default TextComponent;