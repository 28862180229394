import React from 'react';
import FinalHeaderComponent from './final/FinalHeaderComponent';
import BeneficiFinaliComponent from './final/BeneficiFinaliDesktopComponent';
import ReviewOrDownload from './final/ReviewOrDownloadComponent';
import BookAppointment from './final/BookAppointmentComponent';
import ContactsModalComponent from './ContactsModalComponent';
import { Row, Col, Container} from  "react-bootstrap";
import logo from '../../icons/storepos.svg'

function RisparmioFinaleComponent({main}) {
const [showModal, setShowModal] = React.useState(false);
const [showAppointment, setShowAppointment] = React.useState(false);
return (<Container fluid={true} className="final d-none d-md-flex ">
    <Row noGutters={true}>
        <Col md={4} id="left-banner" role="complementary" >
            <div className="sticky">
            <img src={logo} alt="vai alla home page di Enel X store" />
            <h1>Ecco la soluzione su misura per te</h1>
            </div>
        </Col>
        <Col md={8} id="right-banner" as="main" ref={main} tabIndex={0}>

            <div id="title" >Ecco la soluzione su misura per te</div>
                <FinalHeaderComponent />
                <BeneficiFinaliComponent />
                {/* <ReviewOrDownload />
                <BookAppointment /> */}
                <ReviewOrDownload changeModal={(val)=> setShowModal(val)} />
                <ContactsModalComponent appointment={true} show={showAppointment} close={() => setShowAppointment(false)} />
                <ContactsModalComponent appointment={false} show={showModal} close={() => setShowModal(false)} />
                <BookAppointment changeModal={(val) => setShowAppointment(val)} />
        {/* <Footer /> */}
        </Col>
        </Row>
    </Container>
)
}

export default RisparmioFinaleComponent

