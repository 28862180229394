import utils from "../utils/index.js"
import optionsClima from "./climaai.json"
const sendProject = utils.sendProject
const sendExtra = utils.sendProjectFinal
const sendFoto = utils.sendFoto

const data = {
    "pages": [
        {
            "title": "Dati abitazione",
            "subtitle": "Compila i campi di seguito per fornire indicazioni sulla tua abitazione",
            "items": [
                // {
                //     "name": "Indica l'indirizzo dell'abitazione per cui vuoi effettuare la simulazione",
                //     "type": "label",
                //     "extraClass": "mb-16 mt-0",
                //     "desktopPage":1,
                //     "riepilogo": false
                // }, 
                // {
                //     "name":"Indirizzo",
                //     "type":"address",
                //     "cell": "Indirizzo",
                //     "desktopPage":1
                // },
                {
                    "name":"Indirizzo",
                    "type":"address",
                    "cell": "Indirizzo",
                    "desktopPage":1
                },
                {
                    "name": "Indica le informazioni dell’abitazione",
                    "type": "label",
                    "component": "h2",
                    "extraClass": "mb-16 mt-0",
                    "desktopPage": 2
                },
                {
                    "name": "Tipologia edificio",
                    "cell": "C11",
                    "type": "choicebuttons",
                    "choices": [
                        "Monofamiliare",
                        "Bifamiliare/Villette a schiera",
                        "Piccolo Condominio (2-8 abitazioni)",
                        "Medio Condominio (9-15 abitazioni)",
                        "Grande Condominio (oltre 16 abitazioni)"
                    ],
                    "desktopPage": 2
                },
                  {
                    "name":"È presente un impianto fotovoltaico",
                    "cell": "PresenzaFotovoltaico",
                    "type": "bool",
                    "conditionalRendering": 
                        [
                             {
                                 "cell": "C11",
                                 "operator": "equal",
                                 "value": "Monofamiliare"
                             },
                              {
                                 "cell": "C11",
                                 "operator": "equal",
                                 "value": "Bifamiliare"
                             }
                        ],
                        "desktopPage": 2
                },
                  {
                    "name":"Sono presenti uno o più balconi",
                    "cell": "PresenzaBalcone",
                    "type": "bool",
                    "announceWhen": true,
                    "announceMessage":"Nuovo componente disponibile per la selezione dei balconi",
                    "desktopPage": 2
                },
                 {
                    "name": "Tipologia balconi",
                    "cell": "TipologiaBalconi",
                    "type": "choicebuttons",
                    "choices": [
                        "Ringhiera",
                        "Muratura"
                    ],  
                    "conditionalRendering": 
                        [
                             {
                                 "cell": "PresenzaBalcone",
                                 "operator": "equal",
                                 "value": true
                             }
                        ],
                        "desktopPage": 2
                },
                {
                    "name":"Abitazione ristrutturata negli ultimi 10 anni",
                    "cell": "RistrutturatoUltimi10Anni",
                    "type": "bool",
                    "announceWhen": 
                        { 
                            true: "Nuovo componente disponibile per la selezione della coibentazione dell'edificio",
                            false: "Nuovo componente disponibile per la selezione dell'anno di costruzione dell'edificio"
                        },
                    "desktopPage": 2
                },
                {
                    "name":"Condominio/edificio coibentato",
                    "description": "La coibentazione consiste in interventi che isolano l’edificio impedendo scambi di calore che possono generare dispersione termica.",
                    "cell": "Coibentato",
                    "type": "bool",
                     "conditionalRendering": 
                        [
                             {
                                 "cell": "RistrutturatoUltimi10Anni",
                                 "operator": "equal",
                                 "value": true
                             }
                        ],
                        "desktopPage": 2
                },
                {
                    "name": "Anno di costruzione",
                    "cell": "C10",
                    "type": "choicebuttons",
                    "choices": [
                        "Non so",
                        "Prima del 1919 e non ristrutturata di recente",
                        "1919-1945 e non ristrutturata di recente",
                        "1946-1960 e non ristrutturata di recente",
                        "1961-1980 e non ristrutturata di recente",
                        "1981-1990 o recentemente ristrutturata senza coibentazione",
                        "1991-2005",
                        "Dopo il 2005 o ristrutturata con coibentazione"
                    ],
                    "conditionalRendering": [{
                        "cell": "RistrutturatoUltimi10Anni",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 2
                },
                {
                    "name": "Classe energetica",
                    "cell": "C36",
                    "type": "choicebuttons",
                    "choices": [
                        "Non so",
                        "A4",
                        "A3",
                        "A2",
                        "A1",
                        "B",
                        "C",
                        "D",
                        "E",
                        "F",
                        "G"
                    ],
                    "desktopPage": 2
                },
                {
                    "name": "A che piano si trova l'abitazione?",
                    "extraClass": "mb-sm-16 mt-sm-0",
                    "cell": "C12",
                    "type": "choiceatview",
                    "choices": [
                    {
                        "text": "Ultimo piano",
                        "image":2,
                        "value":"Ultimo piano"
                    },
                    {
                        "text": "Piano intermedio",
                        "image": 1,
                        "value":"Piano intermedio"
                    },
                   {
                        "text": "Piano terra",
                        "image": 0,
                        "value":"Piano terra"
                    }], 
                    "conditionalRendering": 
                    [
                         {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Piccolo Condominio (2-8 abitazioni)",
                         },
                          {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Medio Condominio (9-15 abitazioni)",
                         },
                         {
                            "cell": "C11",
                            "operator": "equal",
                            "value":   "Grande Condominio (oltre 16 abitazioni)",
                        }
                    ],
                    "desktopPage": 3
                },
                {
                    "name": "Quanto è grande la casa (metri quadri)?",
                    "placeholder": "Dimensione casa (metri quadri)",
                    "cell": "C13",
                    "type": "slider",
                    "unit": "m2",
                    "min": 28,
                    "max": 500,
                    "info": {
                        "title":"Dimensione minima in metri quadri consentita",
                        "subtitle":"La dimensione minima consentita dalla normativa di un edificio abitabile, comprensivo dei servizi (ad esempio i bagni e le cucine), non può essere inferiore a 28 metri quadri."
                    },
                    "extraClass": "",
                    "markers": [
                        "28",
                        "100",
                        "200",
                        "300",
                        "400",
                        "500"
                    ],
                    "desktopPage": 4
                },
                {
                    "name": "Quanti locali ha la casa?",
                    "placeholder": "Locali totali",
                    "description": "Puoi selezionare fino ad un massimo di 20 locali",
                    "info": {
                        "title":"Cosa si intende per locali?",
                        "subtitle":"Per locali si intendono camere, studi, saloni e sono esclusi i servizi (ad esempio i bagni e le cucine)"
                    },
                    "cell": "C16",
                    "type": "stepper",
                    "min": 1,
                    "step": 1,
                    "max": 20,
                    "defaultValue":1,
                    "text":"Locali",
                    "icon": "casa",
                    "extraClass": "mt-16 divider",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 4
                },
                {
                    "name": "Quante persone vivono nella casa in totale?",
                    "placeholder": "Persone totali",
                    "description":"Puoi selezionare fino ad un massimo di 20 inquilini",
                    "cell": "C15",
                    "type": "stepper",
                    "min": 1,
                    "defaultValue":1,
                    "step": 1,
                    "extraClass": "mt-16",
                    "max": 20,
                    "text":"Inquilini",
                    "icon": "inquilini",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 4
                },
                // {
                //     "name": "Quanti mesi all’anno è abitata la casa?",
                //     "cell": "C17",
                //     "type": "stepper",
                //     "step": 1,
                //     "icon": "mesi",
                //     "text": "Mesi",
                //     "min": 1,
                //     "max": 12,
                //     "defaultValue":12,
                //     "markers": [
                //         "1",
                //         "12"
                //     ],
                   
                //     "desktopPage": 5
                // },
                // {
                //     "name": "La casa è prevalentemente abitata nel periodo invernale",
                //     "cell": "C18",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C17",
                //              "operator": "notequal",
                //              "value": "12"
                //          }
                //     ],
                //     "desktopPage": 5
                // },
                {
                    "name": "Ci sono climatizzatori in casa?",
                    "cell": "C19",
                    "type": "bool",
                    "component": "h2",
                    "desktopPage": 5
                },
                {
                    "name": "I climatizzatori hanno anche funzione riscaldamento",
                    "cell": "CondizionatoriRiscaldamento",
                    "type": "bool",
                    "disableIf": {
                        "cell": "C19",
                        "operator":"equal",
                        "value": false
                    },
                    // "conditionalRendering":[{
                    //     "cell": "C19",
                    //     "operator":"equal",
                    //     "value": true
                    // }],
                    "desktopPage": 5
                }              
            ]
        },
        {
            "title": "Fonti energetiche",
            "subtitle": "Indica le fonti principali utilizzate",
            "items": [
                {
                    "name": "La casa ha il riscaldamento centralizzato",
                    "cell": "C24",
                    "type": "bool",
                    "component": "h2",
                    "announceWhen": false,
                    "announceMessage":"Nuovo componente disponibile per la selezione della fonte energetica utilizzata per il riscaldamento",
                    "desktopPage": 1
                },
                {
                    "type": "label",
                    "name": "Quali fonti energetiche utilizzi per tipologia di consumo?",
                    "desktopPage": 1,
                    "component": "h2",
                    "extraClass": "mt-0"
                },
                {
                    "name": "Seleziona fonte riscaldamento",
                    "cell": "C25",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "conditionalRendering": [{
                        "cell": "C24",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 1
                },
                {
                    "name": "Seleziona fonte acqua calda",
                    "cell": "C26",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
                {
                    "name": "Seleziona fonte cottura cibi",
                    "cell": "C27",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
                // {
                //     "name": "Ho il serbatoio GPL interrato",
                //     "cell": "C28",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C27",
                //              "operator": "equal",
                //              "value": "GPL"
                //          },
                //          {
                //             "cell": "C26",
                //             "operator": "equal",
                //             "value": "GPL"
                //         },
                //         {
                //             "cell": "C25",
                //             "operator": "equal",
                //             "value": "GPL"
                //         }
                //     ],
                //     "desktopPage": 1
                // },
                {
                    "name": "La casa è dotata di solare termico",
                    "cell": "C29",
                    "type": "bool",
                    "component": "h2",
                    "desktopPage": 1
                }, 
                // {
                //     "name": "Conosci la potenza del tuo contatore?",
                //     "cell": "C61",
                //     "type": "bool",
                //     "info": {
                //         "title":"Non conosci la tua attuale potenza?",
                //         "subtitle":"La potenza (kW) è un dato facoltativo. Se non conosci la tua potenza (kW) attiva l’opzione “non la conosco” e procedi con la simulazione. Utilizzeremo un valore di potenza medio come valore di calcolo. "
                //     },
                //     "desktopPage": 2
                // }, 
                {
                    "name": "Conosci la potenza del tuo contatore?",
                    "facsimile": {
                        "image": [0],
                        "title":"Visualizza FAC-SIMILE",
                        "html": `<div >
                         <b> Pagina 2 della bolletta</b> <br>
                         Se non riesci a trovare le informazioni, consulta la <b><a href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>
                        </div>`
                    },
                    "type": "slider",
                    "cell": "D61",
                    "unit": "kW",
                    "min": 3,
                    "max": 10,
                    "step": 0.5,
                    "defaultValue": 3,
                    "component": "h2",
                    "cellSiNo": "C61",
                    "info": {
                        "title":"Non conosci la tua attuale potenza?",
                        "subtitle":"La potenza è una informazione facoltativa. Se non la conosci, seleziona l’opzione “non la conosco” e procedi con la simulazione. Utilizzeremo il valore di potenza più diffuso (3 kW)"
                    },
                    "sr": "Non conosco la potenza del mio contatore",
                    // "conditionalRendering":[{
                    //     "cell": "C61",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "disableIf": {
                        "cell": "C61",
                        "operator": "equal",
                        "value": "No"
                    },
                    // "extraClass": "force-mobile",
                    // "canBeNull":"true",
                    "desktopPage": 2
                }
                // {
                //     "name": "Conosci la tua attuale potenza (kWh)?",
                //     "type": "optional",
                //     "cell": "D61",
                //     "cellSiNo": "C61",
                //     "min": 3,
                //     "max": 10,
                //     "step": 0.5,
                //     "canBeNull": true,
                //     "numbertype": "input",
                //     "info": {
                //         "title":"Non conosci la tua attuale potenza?",
                //         "subtitle":"La potenza (kW) è un dato facoltativo. Se non conosci la tua potenza (kW) attiva l’opzione “non la conosco” e procedi con la simulazione. Utilizzeremo un valore di potenza medio come valore di calcolo. "
                //     },
                //     "desktopPage": 2         
                // }
            ]
        },
        {
            "title": "Consumi attuali",
            "subtitle": "Indica le tue abitudini di consumo energetico",
            "items": [
                {
                    "type": "label",
                    "name": "<h2 class='fonti-text'>Indica la spesa annua per ogni fonte energetica dell’abitazione.</h2><div class='fonti-sub'>Di seguito trovi un FAC-SIMILE della nostra bolletta che ti aiuterà a trovare queste informazioni.</div>",
                    "desktopPage": 1,
                    "extraClass":"no-margin"     
                },
                {
                    "type": "percentage",
                    "name": "",
                    "cell": "C31",
                    // "subtitle": "Senza canone RAI",
                    "canBeNull": true,
                    "after": "Se non riesci a trovare tutte le informazioni sulla tua spesa puoi selezionare l'opzione \"Non la conosco\" e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all'abitazione.",
                    "desktopPage": 1                    
                },
                { 
                    "type":"label",
                    "name": " Di seguito trovi un FAC-SIMILE della nostra bolletta per capire dove trovare questa informazione.",
                    "component": "h2",
                    "facsimile": true,
                    "desktopPage": 2
                },
                {
                    "name": "Conosco il consumo elettrico annuo dell'abitazione",
                    "cell": "C34",
                    "type": "bool",
                    "desktopPage": 2
                }, 
                {
                    "name": "Indica il consumo elettrico annuo in kWh",
                    "type": "slider",
                    "unit": "kWh/a",
                    "cell": "C35",
                    "min": 0,
                    "max": 15000,
                    "step": 100,
                    "info": {
                        "title":"Non conosci il tuo consumo elettrico annuo?",
                        "subtitle":"Il consumo elettrico (kWh/a) è un dato facoltativo. Se non conosci il tuo consumo disattiva l'opzione \"Conosco il mio consumo elettrico annuo\" e procedi con la simulazione. Utilizzeremo una stima basata sulle informazioni relative all'abitazione"
                    },
                    "sr": "Non conosco il mio consumo energetico annuo",
                    // "conditionalRendering":[{
                    //     "cell": "C34",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "disableIf": {
                         "cell": "C34",
                         "operator": "equal",
                         "value": false
                    },
                    "canBeNull":"true",
                    "desktopPage": 2
                }
            ],
            "onSubmit": sendProject
        },
        {
            "title": "Ecco il risparmio calcolato per te",
            "subtitle": "Per risparmiare sulla bolletta, efficienta i tuoi consumi passando a soluzioni alimentate con l’energia elettrica",
            "showBack": false,
            "nextText": "CONTINUA",
            "items": [{
                "type":"summary",
                "desktopPage": 1,
                "riepilogo": false
            }]
        },
        {
            "title": "Configura la tua soluzione",
            "hideSubtitleMobile": true,
            "subtitle": "Seleziona le tipologie di consumi su cui vuoi ricevere un’offerta più dettagliata che risponde alle tue esigenze.",
            "items": [
                {
                    "type": "label",
                    "name": "Seleziona le tipologie di consumi su cui vuoi ricevere un’offerta più dettagliata che risponde alle tue esigenze.",
                    "desktopPage": 1,
                    "extraClass": "d-lg-none no-margin mb-3"     
                },
                {
                    "type": "label",
                    "component": "h2",
                    "name":"Seleziona le tipologie di consumi su cui vuoi effettuare una simulazione più dettagliata",
                    "desktopPage": 1,
                    "extraClass": "d-none d-lg-block no-margin mb-3"
                },
                {
                    "type": "multichoice",
                    "name": "",
                    "choices": [{
                        "text": "Riscaldamento",
                        "image": 0,
                        "cell": "C41",
                        "risp":"Risc_Risparmio",
                        "conditionalRendering":[{
                            "cell": "C24",
                            "operator": "notequal",
                            "value": true
                        }, 
                        {
                            "cell": "C25",
                            "operator": "notequal",
                            "value": "Elettrico"
                        }]
                    },
                    // {
                    //     "text": "Acqua calda sanitaria",
                    //     "image": 1,
                    //     "cell": "C45",
                    //     "risp":"AC_Risparmio",
                    //     "conditionalRendering":[
                    //     {
                    //         "cell": "C26",
                    //         "operator": "notequal",
                    //         "value": "Elettrico"
                    //     }]
                    // },
                    // {
                    //     "text": "Piano Cottura",
                    //     "image": 2,
                    //     "cell": "C48",
                    //     "risp":"Cott_Risparmio",
                    //     "conditionalRendering":[
                    //         {
                    //             "cell": "C27",
                    //             "operator": "notequal",
                    //             "value": "Elettrico"
                    //         }]
                    // },
                    {
                        "text": "Energia solare",
                        "image":3,
                        "cell": "C53"
                    }],
                    "required": false,
                    "desktopPage": 1                    
                },
            ],
            "onCheck": sendFoto
        },
        {
            "title": "Riscaldamento",
            "subtitle":"Climatizzatore a pompa di calore",
            "page": 4,
            "conditionalRendering": 
                    [
                         {
                             "cell": "C41",
                             "operator": "equal",
                             "value": true
                         }
                    ],
            "items":[
                {
                    "type":"label",
                    "name": "In base alle informazioni fornite sull'abitazione, ti proponiamo:",
                    "desktopPage": 1,
                    "extraClass":"mb-0"
                },
                {
                    "type": "riscaldamento",
                    "desktopPage": 1
                },
                // {
                //     "pre": " ",
                //     "name": "Voglio scegliere il numero di climatizzatori",
                //     "unit":"Climatizzatori a pompa di calore",
                //     "placeholder": "",
                //     "cell": "NumeroClimatizzatori",
                //     "response": "C16",
                //     "type": "optional",
                //     "min": 1,
                //     "max": 9,
                //     "canBeNull": true,
                //     "desktopPage": 1,
                //     "forceValue": true,
                //     "numbertype": "input"   
                // }, 
                // {
                //     "pre": "Questa è la configurazione che suggeriamo per te:",
                //     "name": "Voglio modificare la configurazione*",
                //     "placeholder": "",
                //     "cellSiNo": "ModificaConfigurazione",
                //     "cell": "C42",
                //     "optionsDriver": "NumeroClimatizzatori",
                //     "type": "optional",
                //     "min": 1,
                //     "max": 3,
                //     "disclaimer": "*Potremmo aver bisogno di effettuare altri approfondimenti per la tua abitazione ad esempio se ci sono stanze comunicanti, vincoli ambientali o tecnici.",
                //     "canBeNull": true,
                //     "required":false,
                //     "choices": optionsClima,
                //     "desktopPage": 1,
                //     "info": {
                //         "title":"Cosa si intende per configurazione?",
                //         "subtitle":"Configurare un impianto significa scegliere la combinazione di condizionatori cosiddetti \"monosplit\", che sono costituiti da un'unità esterna (motore) e un'unità interna (split), e/o \"multisplit\", che invece sono costituiti da un motore e due o più split."
                //     },
                // }, 
                {
                    "name": "",
                    "extraClass": "my-16",
                    "type": "spacer",
                    "desktopPage": 1   
                },
                {
                    "name": "Quale livello di prestazione desideri?",
                    "type": "label",
                    "extraClass": "d-lg-none",
                    "desktopPage": 1   
                },
                {
                    "name": "Quale livello di prestazione desideri?",
                    "cell": "PrestazioneRiscaldamento",
                    "type": "choicebuttons",
                    "choices": [
                        "Più economico €",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                }
            ]
        },
        {
            "title": "Acqua calda",
            "subtitle":"Scaldacqua a pompa di calore",
            "page": 4,
            "conditionalRendering": 
            [
                 {
                     "cell": "C45",
                     "operator": "equal",
                     "value": true
                 }
            ],
            "items":[
                {
                    "type": "label",
                    "name":"Tipo di soluzione <br />In base alle informazioni fornite sull'abitazione ti proponiamo:",
                    "cell": "",
                    "component": "h2",
                    "desktopPage": 1
                },
                {
                    "type": "label",
                    "name": "<div><b>{cell} Scaldacqua a pompa di calore </b> </div>",
                    "cell": "C46",
                    "extraClass":"mt-0 scaldaquabox",
                    "desktopPage": 1
                },
                    {
                    "name": "troverai il dettaglio nel report finale",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "mt-0"
                }, 
                // {
                //     "pre": "",
                //     "name": "In base alle informazioni fornite sull'abitazione ti proponiamo:",
                //     "unit":"Scaldacqua a pompa di calore",
                //     "placeholder": "",
                //     "extraClass": "mt-16",
                //     "cell": "C46",
                //     "response": "C46",
                //     "type": "optional",
                //     "canBeNull": true,
                //     "required": false,
                //     "desktopPage": 1,
                //     "numbertype": "input",
                //     "min":1,
                //     "max": 3
                // },   
            
            ]
        },
        {
            "title": "Cottura cibi",
            "subtitle":"Piano cottura a induzione",
            "page": 4,
            "conditionalRendering": 
            [
                 {
                     "cell": "C48",
                     "operator": "equal",
                     "value": true
                 }
            ],
            "items":[
                {
                    "pre": "In base alle informazioni fornite sull'abitazione ti proponiamo:",
                    // "unit":"Piano di cottura a induzione libera",
                    "name": "Voglio modificare la configurazione",
                    "placeholder": "",
                    "cellSiNo": "C48Test",
                    "cell": "D48_val",
                    "response": "D48",
                    "type": "optional",
                    "canBeNull": true,
                    "desktopPage": 1,
                    "disclaimer": "*Potrebbe essere necessario effettuare successivi approfondimenti al fine di identificare la migliore configurazione possibile per il tuo edificio (se ci sono stanze comunicanti, vincoli ambientali o tecnici).",
                    "choices": ["59.2cm","60cm", "75cm"]   
                },   
                // {
                //     "name": "Livello di prestazione",
                //     "cell": "PrestazioneCottura",
                //     "type": "choice",
                //     "choices": [
                //         "Più economico €",
                //         "Miglior rapporto qualità/prezzo €€",
                //         "Top di gamma €€€"
                //     ],
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C123132DaCambiare",
                //              "operator": "notequal",
                //              "value": false
                //          }
                //     ],
                //     "desktopPage": 1   
                // }
            ]
        },
        {
            "title": "Autoproduzione energia",
            "subtitle":"Fotovoltaico e/o batteria",
            "page": 4,
            // "notCondo": true,
            "conditionalRendering": 
            [
                {
                    "cell": "C11",
                    "operator": "notin",
                    "value": ["Monofamiliare", "Bifamiliare"]
                },
                 {
                     "cell": "C53",
                     "operator": "equal",
                     "value": true
                 },
                 {
                    "cell": "C54",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "<div>Tipo di soluzione preferita </div><div class='small-text'>Scegli la tipologia di impianto che preferisci. Un impianto con sistema di accumulo (batteria) ti permette di conservare l'energia prodotta per utilizzarla anche quando l'impianto non è in funzione, ad esempio nelle ore notturne. </div>",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin"
                }, 
                {
                    
                    "cell": "C12DaCambiare",
                    "type": "choiceatviewfoto",
                    "choices": [{
                        "text": "Pannelli solari e batteria",
                        "image": 1,
                        "cell": "C54"
                    },
                    {
                        "text": "Pannelli solari",
                        "image": 0,
                        "cell":"C53"
                    }],
                    "required":false,
                    "desktopPage": 1
                },
                {
                    "name": "Quale livello di prestazione desideri?",
                    "type": "label",
                    "desktopPage": 1   
                },
                {
                    "name": "Livello di prestazione",
                    "cell": "PrestazionePennelli",
                    "type": "choicebuttons",
                    "choices": [
                        "Più economico €",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                }
            ]
        },
        {
            "title": "Potenza contatore",
            "subtitle":"",
            "page": 5,        
            "items":[
                // {
                //     "name":"L'offerta di energia elettrica è 100% da fonti rinnovabili",
                //     "cell": "C78",
                //     "type": "choice",
                //     "extraClass": " mt-16",
                //     "choices": [
                //         "Si",
                //         "No",
                //         "Non so"
                //     ],
                //     "desktopPage": 1   
                // },
                // {
                //     "name":"Voglio scegliere la potenza",
                //     "cell": "C84",
                //     "type": "bool",
                //     "extraClass": " mt-16",
                //     "desktopPage": 1   
                // },
                // {
                //     "type": "slider",
                //     "cell": "D84",
                //     "min": 1,
                //     "max": 10,
                //     "step": 1,
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C84",
                //              "operator": "equal",
                //              "value": true
                //          },
                //     ]
                // }
                {
                    "pre": "Nuova potenza suggerita in base ai consumi futuri:",
                    "name": "Voglio scegliere la potenza",
                    "placeholder": "",
                    "cellSiNo": "C84",
                    "cell": "D84",
                    "response": "C82",
                    "type": "optional",
                    "min": 3,
                    "max": 10,
                    "numbertype": "input",
                    "numberCell":"D84",
                    "unit": "kW",
                    "canBeNull": true,
                    "desktopPage": 1   
                }, 
            ],
            "onSubmit": sendExtra,
        },
        {
            "title": "Fine",
            "items": []
        }
    ]
}

export default data;