
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useEffect } from 'react';
import SliderWithThumbComponent from './SliderWithThumbComponent';
import SelectComponent from './SelectComponent';
import switchoff from "../../icons/Switch off.svg"
import switchon from "../../icons/Switch on.svg"
import InfoButtonComponent from './InfoButtonComponent';
import climaAi from "../../utils/climaai.json"
import SelectButtonComponent from './SelectButtonComponent';


function OptionalComponent({ name, cell, min, max, step, pre, 
    response, disclaimer,choices, extraClass, 
    info,forceValue,
    cellSiNo, numbertype, optionsDriver, unit }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    let currentValue = undefined

    const moveArrow = (e) => {
        const range = e.target;
        const value = range.value;
        const min = range.min || 0;
        const max = range.max || 100;
        let newVal = Number(((value - min) * 100) / (max - min));
        if (newVal < 0) newVal = 0;
        if (newVal > 100) newVal = 100;
        const thumb = range.closest(".sliderwiththumb").querySelector(".thumb");
        const fill = range.closest(".sliderwiththumb").querySelector(".fill");
        fill.style.width = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        if (newVal < 15)
            thumb.style.left = `calc(${newVal}% + (${8  - newVal * 0.15}px))`;    
        else if (newVal > 94)
            thumb.style.left = `calc(${newVal}% + (${-20 - newVal * 0.15}px))`;
        else    
            thumb.style.left = `calc(${newVal}% + (${0 - newVal * 0.15}px))`;
    }


    switch (cell){
        case "C16":
            currentValue =  Math.min(parseInt(data[cell]), 9)
            break;
        // case "D84":
        //     console.log(data["D84"], data["C82"])
        //     const slider = document.querySelector(".slider" + cell + " input")  
        //     debugger;
        //     if (!data["D84"]){
        //         currentValue = data["C82"]

        //         if(slider) {
                    
        //             slider.value = currentValue
        //             moveArrow({target: slider})
        //         }
        //     }
        //     break
        // case "D48_val":
        //      currentValue = data["D48"]
        //      break;
        default:
            currentValue = data[cell]
    }

    // const slider = document.querySelector(".slider" + cell + " input")  
    // if (slider && (currentValue === undefined || currentValue === null || currentValue === "No")){
    //     if (response && data[response] !== undefined && data[response] !== null){
    //         slider.value = data[response]
    //     }
    //     else {
    //         slider.value = max / 2 + min / 2
    //     }
    //     moveArrow({target: slider})
    // }
    
    if (cell === "NumeroClimatizzatori" && (data["NumeroClimatizzatori"] === undefined|| data["NumeroClimatizzatori"] === null)){
        dispatch(set({
            cell: "NumeroClimatizzatori",
            value: currentValue
        }))
    }
    
    if (currentValue === "No") currentValue = false;

    let suggestedValue
    let shouldRender = (cellSiNo !== undefined && data[cellSiNo] === true)
    if (cellSiNo === undefined) shouldRender= true; 
    if (cell === "NumeroClimatizzatori") shouldRender =!( currentValue == false || currentValue == undefined || currentValue == null)

    //  console.log(optionsDriver === "NumeroClimatizzatori",data["NumeroClimatizzatore"],data["NumeroClimatizzatore"] === false)
    if (optionsDriver === "NumeroClimatizzatori" && (data["NumeroClimatizzatori"] === false || data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === "No")){
        suggestedValue = optionsDriver ? 
            choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)[0]
            : data[response]
    }
    else {
        if (optionsDriver)
            suggestedValue = choices.filter(x => x.N == data[optionsDriver]).map(x => x.name)[0]
        else if (response === "C16")
            suggestedValue = Math.min(parseInt(data["C16"]), 9)
        else        
            suggestedValue = data[response]
    }

    // console.log("CURRENT", currentValue)
    // if (!suggestedValue) suggestedValue="1"

    if (cell === "C42" && !data["C42"] && data["ModificaConfigurazione"] === true){
        // debugger
        // dispatch(set({
        //     cell: "C42",
        //     value: suggestedValue
        // }))
        // dispatch(set({
        //     cell: "ModificaConfigurazione",
        //     value: true
        // }))
    }


    const setValue = (e) => {
        if (min && e < min) {
            e = min
        }
        if (max && e > max){
            e = max
        }
        dispatch(set({
            cell: cell,
            value: e
        }))

        if (cell === "NumeroClimatizzatori"){
            const climaChoice = climaAi.filter(x => x.N == e).map(x => x.name)[0]
            dispatch(set({
                cell:"C42",
                value: climaChoice
            }))
            dispatch(set({
                cell: "ModificaConfigurazione",
                value: true
            }))
        }
    }

    const getChoices = () => { 
        if (data[optionsDriver] !== "No" && data[optionsDriver] !== false) 
            return choices.filter(x => x.N == data[optionsDriver]).map(x => x.name) 
        else 
            return choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)
    }

    return (
        <div className={"slider-component d-flex flex-column " + (extraClass !== undefined ? extraClass:""  )}>
            {pre && <><h2 className={(pre !== " " ? "mt-16": "")+ " pre"  + (shouldRender === true ? " disabled" : "")}>{pre}</h2>
            <div className={"mb-16 mt-16" + (shouldRender === true ? " disabled" : "")}><b >{suggestedValue} {unit}</b></div> </>}
            {cell && <>
            <div className={"slider-component-switch d-flex flex-row flex-lg-row-reverse flex-row align-items-center justify-content-between justify-content-lg-end mb-16 "}>
                <label htmlFor={"id-"+name} className={shouldRender === true ? " bold" : ""}>{name}</label>
                <div className="me-md-2">
                    { cellSiNo ?
                     <button id={"id-"+ name} title={name} className="no-border no-bg pt-1" onClick={() => {
                        const currentVal = data[cellSiNo]
                        const slider = document.querySelector(".slider" + cell + " input")                            
                        if (currentVal) // need to remove value
                        {
                            dispatch(set({
                                cell: cell,
                                value: undefined
                            }))
                            
                            if(slider){
                                slider.value = 0
                                moveArrow({target: slider})
                            }
                        }
                        else{
                            if (cell === "C42"){
                            dispatch(set({
                                cell: cell,
                                value: suggestedValue
                            }))
                            dispatch(set({
                                cell: "ModificaConfigurazione",
                                value: true
                            }))

                            if(slider){
                                slider.value = suggestedValue
                                moveArrow({target: slider})
                            }
                               
                        }
                            if (cell === "D48_val"){
                                dispatch(set({
                                    cell: cell,
                                    value: "59.2cm"
                                }))
                            }
                        } 
                        
                        dispatch(set({
                            cell: cellSiNo,
                            value: !data[cellSiNo]
                        }))
                    }} ><img src={cellSiNo === "ModificaConfigurazione" ? (data[cellSiNo] === true  ? switchon : switchoff) : (data[cellSiNo] === true|| currentValue ? switchon : switchoff)} 
                        alt="switch" />
                        </button>
                :
                <button id={"id-"+ name} title={name} className="no-border no-bg pt-1" onClick={() => {
                    if (cell === "NumeroClimatizzatori" && (currentValue === false || currentValue === undefined)) {
                        dispatch(set({
                            cell: cell,
                            value: suggestedValue
                        }))
                        // dispatch(set({
                        //     cell: "ModificaConfigurazione",
                        //     value: true
                        // }))
                        const slider = document.querySelector(".slider" + cell + " input")                            
                        if(slider){
                            slider.value = suggestedValue
                            moveArrow({target: slider})
                        }
                    }
                    else {
                        dispatch(set({
                            cell: cell,
                            value: !currentValue
                        }))
                        dispatch(set({
                            cell: "ModificaConfigurazione",
                            value: undefined
                        }))
                        const slider = document.querySelector(".slider" + cell + " input")                            
                        if(slider){
                            slider.value = 1
                            moveArrow({target: slider})
                        }
                }
                    
                }}  >
                <img src={currentValue == false || 
                    currentValue == undefined || 
                    currentValue == null ? switchoff : switchon} 
                    alt="" />
                    </button>}
                </div>
                <div id="liveRegion" 
                    aria-live="polite" aria-atomic="true" 
                    className="visually-hidden">Switch per {name} attualmente {currentValue ? "Attivato " : "Non Attivato "}</div>
           
            </div> 
             

             {/* {currentValue !== false && currentValue !== "No" && choices === undefined && shouldRender &&  */}
             { choices === undefined && 
                <SliderWithThumbComponent 
                cell={cell}
                extraClass={!shouldRender ? "disabled mt-16" : "mt-16"}
                min={min}
                max={max} 
                unit={unit}
                step={step}
                name={name}
                moveArrow={moveArrow}
                disabled={!shouldRender}
                currentValue={currentValue}
                forceValue={forceValue}
                setCurrentValue={(value) =>{ 
                    if (shouldRender){
                        dispatch(set({
                                cell: cell,
                                value: value
                            })) 
                    if (cellSiNo) dispatch(set({
                        cell: cellSiNo,
                        value: true
                    }))
                }
                }
            } 
            />
            }

            {currentValue !== false &&  choices && shouldRender &&<div className="d-flex align-items-center winfo">
                <SelectButtonComponent 
                    extraClass={"flex-grow-1"} 
                    name={"Configurazione"} 
                    choices={optionsDriver ? getChoices() : choices} 
                    cell={cell} />
                {info && <InfoButtonComponent extraClass="mw40" {...info} />    }
                </div>
            }
            </>}
           
            { disclaimer && <div className="mt-16 mb-16 small-text">{disclaimer}</div> }
        </div>
    );
}



export default OptionalComponent;