
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, {useRef, useState } from 'react';
import switchoff from "../../icons/Switch off.svg"
import switchon from "../../icons/Switch on.svg"
import chevron from "../../icons/icon/dropdownarrow.svg"
import InfoButtonComponent from './InfoButtonComponent';


function SwitchComponent({ name, cell, extraClass, type, info, nonso, description, href, disableIf, component, announceMessage,announceWhen }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const [firstTime, setFirstTime] = useState(true)
    const disabled = disableIf && (data[disableIf.cell] == disableIf.value || data[disableIf.cell] == undefined)

    const createAnnouncement = (currentValue) => {
        let announcement = ""
        if (currentValue === announceWhen){
            if (announceWhen === false && firstTime){
            }
            else {
                announcement = announceMessage
            }
        }
        else if (announceWhen !== undefined) {
            announcement = announceWhen[currentValue]
        }
        return announcement
    }

    let announcement = createAnnouncement(currentValue)

    const onSwitch = (e) => {
        if (href || disabled) return
        setFirstTime(false)

        dispatch(set({
            cell: cell,
            value: !currentValue
        }))

        const shouldRefocus = createAnnouncement(!currentValue)
        if (shouldRefocus !== undefined && shouldRefocus.length > 0) {
            // setTimeout(() => {
            //     const focusableElements = document.querySelectorAll(
            //         'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
            //     );
            //     const currentFocus = Array.prototype.indexOf.call(focusableElements, document.activeElement);
            //     focusableElements[currentFocus+1].focus()
            // }, 50)
        }
        e.preventDefault()
    }
 
    return (<>
    <div
        role="switch"
        aria-roledescription='interruttore'
        aria-checked={currentValue && currentValue !== "Non so"} 
        className={(disabled ? "disab " : "") + "switch-component d-flex flex-row align-items-center justify-content-between " + (data ? "filled " : "") + (extraClass ?? "") + (description === undefined ? " mb-16 " : "") }>
            {component ? 
                    <h2 className={"switch-text clickable " + (currentValue === true ? "bold": "")} 
                        onClick={onSwitch}>
                            <a href={href} 
                                target="_blank" 
                                rel="noreferrer" 
                                aria-hidden={true}>
                                {name}
                            </a>
                            {info && <InfoButtonComponent {...info} />}
                        </h2> 
                        :  
                        <label className={"switch-text clickable " + (currentValue === true ? "bold": "") } 
                                aria-hidden={true}
                                onClick={onSwitch}>
                        <a href={href} target="_blank" rel="noreferrer">
                            {name} 
                        </a>{info && <InfoButtonComponent {...info} />}
                        </label>
            }
          
            <div className="">
                {type === "check" ? 
                <input type="checkbox" 
                    aria-checked={currentValue}
                    aria-label={"Checkbox " + name} 
                    checked={currentValue === true ? true : false} onClick={(e) => dispatch(set({
                    cell: cell,
                    value: e.currentTarget.checked
                }))} /> : 
                <button className="no-border no-bg px-0"  
                    id={"id-" + cell}  
                    onClick={onSwitch}>
                    <img src={currentValue && currentValue !== "Non so" ? switchon : switchoff} 
                     alt={""}
                />
                <div id="liveRegion" 
                    aria-live="polite" 
                    aria-atomic="true" 
                    className="visually-hidden">{name} attualmente {currentValue ? "Attivato " : "Non Attivato "} 
                    {disabled && " attivabile selezionando la casella precendente."}
                   {announcement}</div>
                </button>}
                
            </div>
           
        </div>
        {description && <div className="switch-description mb-16">
            {description}
            </div>}
        {nonso && <div
            role="switch"
            aria-checked={currentValue === "Non so"}
            aria-roledescription='checkbox' className="bool-nonso" onClick={() =>  dispatch(set({
                        cell: cell,
                        value: "Non so"
                    }))}>
            <input type="checkbox" id={"id" + cell} />
            <label htmlFor={"id" + cell}> Non so</label>
        </div>}
        </>
    );
}

export default SwitchComponent;