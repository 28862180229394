import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useState, useRef } from 'react';
import SwitchComponent from './SwitchComponent';
import SliderComponent from './SliderComponent';
import switchoff from "../../icons/Switch off.svg"
import switchon from "../../icons/Switch on.svg"
import SelectButtonComponent from './SelectButtonComponent';
import climaAi from "../../utils/climaai.json"
import InfoButtonComponent from './InfoButtonComponent';

const ConfigSwitchComponent = ({ cell, data, text, onClick, activationMessage }) => {
    let currentValue = data[cell] ?? false
    if (cell === "NumeroClimatizzatori" && data[cell] !== false && data[cell] !== undefined) currentValue = true

    return(
    <button
    role="switch"
    aria-roledescription={'interruttore per ' + text}
    aria-checked={currentValue === true}
    onKeyDown={(e) => {if (e.key === "Enter" || e.key === " ") onClick()}} 
    className={"switch-component d-flex flex-row align-items-center justify-content-between w-100 plain no-border " + (data ? "filled " : "") }>
        <label className={"switch-text clickable " + (currentValue === true ? "bold": "") } 
            // htmlFor={'id-config'+cell}
           
            onClick={onClick}
            >
       {text}
    </label>
    <div 
        className="no-border no-bg px-0"  
            // id={"id-config"+cell}  
            onClick={onClick} 
            >
            <img role="presentation" src={currentValue !== false ? switchon : switchoff} alt="" />
            <div id="liveRegion" 
                aria-live="polite" 
                className="visually-hidden">
               {currentValue !== false ? activationMessage : ""}</div>
    </div>
</button>)
}

function ConfiguraRiscaldamentoComponent() {
    const dispatch = useDispatch();
    const data = useSelector(get);
    
    let climaChoice = []
    if (data["NumeroClimatizzatori"] === false) {
        climaChoice = climaAi.filter(x => x.N == data["C16"]).map(x => x.name)
    }
    else {
        climaChoice = climaAi.filter(x => x.N == data["NumeroClimatizzatori"]).map(x => x.name)
    }

    if (data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === null) 
        dispatch(set({
            cell: "NumeroClimatizzatori",
            value: Math.min(data["C16"], 9)
        })) 

    const NumeroClimatizzatori = data["NumeroClimatizzatori"] ?? false
    const ModificaConfigurazione = data["ModificaConfigurazione"] ?? false
    if (!ModificaConfigurazione) climaChoice = []
    return (<>
        <div className={"mb-16 mt-16 " + (data["NumeroClimatizzatori"] !== false ? "disa" : "")} >
            <b>{Math.min(data["C16"], 9)} Climatizzatori a pompa di calore</b>
        </div>

       <ConfigSwitchComponent 
                              activationMessage={"E' ora possibile scegliere il numero di climatizzatori"}
                              cell="NumeroClimatizzatori"
                              text="Voglio scegliere il numero di climatizzatori"
                              data={data}
                                onClick={() => {
                                            if (NumeroClimatizzatori) {
                                                dispatch(set({
                                                    cell: "NumeroClimatizzatori",
                                                    value: false
                                                }))
                                            }
                                            else {
                                                dispatch(set({
                                                    cell: "NumeroClimatizzatori",
                                                    value: data["C16"]
                                                }))
                                            }
                                        }} />
        <SliderComponent 
            cell="NumeroClimatizzatori" 
            min={1} max={9} step={1} 
            unit="Climatizzatori a pompa di calore"
            type="range"
            forceDisabled={NumeroClimatizzatori === false} />

        <h2 style={{ fontWeight: "unset", fontSize:"1rem"}} className="mt-16 pre">Questa è la configurazione che suggeriamo per te
        <div className="visually-hidden">{data["NumeroClimatizzatori"] !== false ? " in base al numero di climatizzatori che hai scelto": ""}</div>:</h2>
        <div className={"mb-16 mt-16 " + (data["ModificaConfigurazione"] !== false ? "disa" : "")} >
            <b>{data["NumeroClimatizzatori"]} Climatizzatori a pompa di calore</b>
        </div>

        <ConfigSwitchComponent 
                              cell="ModificaConfigurazione"
                              activationMessage={"E' ora modificare la configurazione dei climatizzatori"}
                              text="Voglio modificare la configurazione*"
                              data={data}
                              onClick={() => {
                                            if (ModificaConfigurazione) {
                                                dispatch(set({
                                                    cell: "ModificaConfigurazione",
                                                    value: false
                                                }))
                                            }
                                            else {
                                                dispatch(set({
                                                    cell: "ModificaConfigurazione",
                                                    value: true
                                                }))
                                            }
                                        }} />

        <div className={"d-flex align-items-center " + (ModificaConfigurazione ? "" : "no-text") } style={{ maxWidth:"430px"}}>
            <SelectButtonComponent 
                extraClass={"flex-grow-1"}
                name="Configurazione"
                cell="C42"
                disabled={!ModificaConfigurazione}
                key={NumeroClimatizzatori}
                choices={climaChoice}
                />

            <InfoButtonComponent  
                title="Cosa si intende per configurazione?"
                subtitle={`Configurare un impianto significa scegliere la combinazione di condizionatori cosiddetti \"monosplit\", che sono costituiti da un'unità esterna (motore) e un'unità interna (split), e/o \"multisplit\", che invece sono costituiti da un motore e due o più split.`}
                    />
        </div>
        <div className="slider-component">
        <div className="mt-16 mb-16 small-text">*Potremmo aver bisogno di effettuare altri approfondimenti per la tua abitazione ad esempio se ci sono stanze comunicanti, vincoli ambientali o tecnici.</div>
        </div>
    </>)
}



export default ConfiguraRiscaldamentoComponent;