
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { Children } from 'react';
import checkedIcon from "../../icons/buttons/radio-button-on.svg"
import uncheckedIcon from "../../icons/icon/radio-empty.svg"

import muratura from "../../icons/buttons/muratura_text.svg"
import ringhiera from "../../icons/buttons/ringhiera_text.svg"

import monofamiliare from "../../icons/buttons/monofamiliare_text.svg"
import bifamiliare from "../../icons/buttons/bifamiliare_text.svg"
import piccolo from "../../icons/buttons/piccolo_text.svg"
import medio from "../../icons/buttons/medio_text.svg"
import grande from "../../icons/buttons/grande_text.svg"

import metano from "../../icons/buttons/metano_text.svg"
import gpl from "../../icons/buttons/gpl_text.svg"
import aria from "../../icons/buttons/aria_text.svg"
import gasolio from "../../icons/buttons/gasolio_text.svg"
import pellet from "../../icons/buttons/pellet_text.svg"
import legna from "../../icons/buttons/legna_text.svg"
import elettrico from "../../icons/buttons/elettrico_text.svg"

import mattina from "../../icons/buttons/mattina.svg"
import pomeriggio from "../../icons/buttons/pomeriggio.svg"
import non from "../../icons/buttons/non.svg"

import a4 from "../../icons/buttons/a4.png"
import a3 from "../../icons/buttons/a3.png"
import a2 from "../../icons/buttons/a2.png"
import a1 from "../../icons/buttons/a1.png"
import b from "../../icons/buttons/b.png"
import c from "../../icons/buttons/c.png"
import d from "../../icons/buttons/d.png"
import e from "../../icons/buttons/e.png"
import f from "../../icons/buttons/f.png"
import g from "../../icons/buttons/g.png"

import top from "../../icons/buttons/top_text.svg"
import miglior from "../../icons/buttons/miglior_text.svg"
import più from "../../icons/buttons/più_text.svg"

import ceRect from "../../icons/buttons/ce-rect.svg"

const imgs = {
    muratura,
    ringhiera,
    monofamiliare,
    bifamiliare,
    piccolo,
    medio,
    grande,
    metano,
    gpl,
    aria,
    gasolio,
    pellet,
    legna,
    elettrico,
    a4,
    a3,
    a2,
    a1,
    b,
    c,
    d,
    e,
    f,
    g,
    top,
    miglior,
    più,
    mattina,
    pomeriggio,
    non
}

function RenderOptionComponent ({ name, cell, action }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const checked = data[cell] === action 

    const handleKeyDown = (e) => {
        if (e.key === "Enter" || e.key === " ") dispatch(set({cell: cell, value: action})) 
        if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
            e.currentTarget.previousElementSibling?.focus();
        }
        if (e.key === "ArrowRight" || e.key === "ArrowDown") {
            e.currentTarget.nextElementSibling?.focus();
        }
        e.preventDefault();
    }

    let text = action + "";
    if (cell === "C10")
        text = text?.replaceAll(" e non ristrutturata di recente", "")
        ?.replaceAll(" o recentemente ristrutturata senza coibentazione", "")
        ?.replaceAll(" o ristrutturata con coibentazione", "")

    if (cell === "C36")
        return <div role="listitem" 
            className={"classe-energetica-box align-self-center px-0 " + (data[cell] === "Non so" ? "disabled" : "") }
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onClick={() => dispatch(set({
                cell: cell,
                value: action
            }))}>
                <button tabIndex={-1} aria-hidden={true} className={"box no-bg p-0 no-border " + (checked ? "active" : "") } >
                {checked && <div className="blue-box-ce">
                        <img src={ceRect} role="presentation" alt="classe-energetica" />
                        <div className="active-text">{text}</div>
                    </div>}
                <img alt="" role="presentation" className="opt-button" src={checked ? checkedIcon : uncheckedIcon} />
                <img alt={"Classe Energetica " + action + (checked ? " selezionato" : "")} 
                     className="opt-image" src={imgs[text.split("/")[0].split(" ")[0].toLowerCase()]} />
            </button>
        </div>
    else if (cell === "C10"  || cell === "C42")
        return  <div tabIndex={0} 
            aria-roledescription='bottone' 
            role="listitem" 
            className="d-flex  align-items-center select-option"
            onKeyDown={handleKeyDown}
            onClick={() => dispatch(set({
                cell: cell,
                value: action
            }))}>
                <div className="mr-4" >
                <img src={checked ? checkedIcon : uncheckedIcon} alt="" />
            </div>
            <div>
                <div className={"main " + (checked ? "bold" : "") }>{text.split("(")[0]}</div>
                {text.split("(")[1] && <div className="sub">{text.split("(")[1].replaceAll(")", "")}</div>}
            </div>
        </div>
    else
    return <div role="listitem" 
                        className="col-6 select-option-button align-self-center" 
                        aria-roledescription='bottone'
                        tabIndex={0}
                        aria-label={action}
                        onKeyDown={handleKeyDown}
                        onClick={() => dispatch(set({
                            cell: cell,
                            value: action
                        }))}>
        <button tabIndex={-1} aria-hidden={true} className={"box " + (checked ? "active" : "") } >
            <img alt="" role="presentation" className="opt-button" src={checked ? checkedIcon : uncheckedIcon} />
            <img alt="" role="presentation" className="opt-image" src={imgs[text.split("/")[0].split(" ")[0].toLowerCase()]} />
        </button>
    </div>
}

export default RenderOptionComponent;
