function InfoModal ({ title, subtitle, close }) {
  return (
    <div className='info-box' role='dialog'>
      <div className='content'>
        <div className='title'>{title}</div>
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></div>
        <button onClick={close} className='big-accent'>
          OK
        </button>
      </div>
     
    </div>
  )
}

export default InfoModal
