import { Col, Row, Table } from "react-bootstrap"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    get
} from '../../reducers/data';

import logo from "../../icons/storepos.svg"
import edit from "../../icons/low_priority.svg"
import checkmark from "../../icons/icon/checkmark.svg"
import report from "../../icons/icon/report.svg"

const TEST_MODE = false

function DesktopStep ({number, copy, icon, active, past}){
    return (
        <><div className={ "desktop-step " + (active ? "active" : "") + (number === 7 ? " last" : "")}>
            <div className="step-box">
                <div className="step-number-box">
                    <div className="step-number">
                        {icon ? <img src={icon} alt=""/> : 
                        <span style={{ lineHeight: "1.125rem"}} aria-hidden={true} >{number}</span>}
                    </div>
                </div>
            </div>
            <div aria-hidden={true} className="step-copy">{copy}</div>
            {/* <div className="step-icon">
                <img src={icon} alt="icon" />
            </div> */}
        </div>
         <div className="visually-hidden">Step numero {number} {copy} {active && " in corso"} {past && " passato"} {!past && !active && " da completare"}</div>
         </> 
    )
}

function Header({ title, subtitle, page, className,hideSubtitleMobile, anchor, main }) {

    const data = useSelector(get);

    const sortedDebugData = Object.keys(data).sort().reduce((prev, key) =>{
        if (data[key] === true)
            prev.push(<tr key={key}><td>{key}</td><td>Si</td></tr>)
        else if (data[key] === false)
            prev.push(<tr key={key}><td>{key}</td><td>No</td></tr>)
        else 
            prev.push(<tr key={key}><td>{key}</td><td>{data[key]}</td></tr>)
        
        return prev
    }, [])

    // const MAX = 5
    // const MIN = 0
    // const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
    const findFirstFocusableElement = container => {
        return Array.from(container.getElementsByTagName("*")).find(isFocusable);
      };

      const isFocusable = item => { 
        if (item.tabIndex < 0) {
          return false;
        }
        switch (item.tagName) {
          case "A":
            return !!item.href;
          case "INPUT":
            return item.type !== "hidden" && !item.disabled;
          case "SELECT":
          case "TEXTAREA":
          case "BUTTON":
            return !item.disabled;
          default:
            return false;
        }
      };

    return (<div id="form-header" className={className} role="complementary">
        <a onClick={(e)=> {
            e.preventDefault(); 
            // const firstFocusable = findFirstFocusableElement(main.current);
            // if (firstFocusable)firstFocusable.focus();
        main?.current?.focus();
     }} 
        href="" tabIndex={0} className="skip" ref={anchor}>Salta al contenuto principale</a>
        { TEST_MODE === false ? 
        <div className="title-box">
            <div>
                <a href="https://enelxstore.com" id="enel-logo" >
                    <img src={logo} className="logo" alt="vai alla home page di Enel X store" />
                </a>
                </div>
            {page !== undefined &&<div className="progress-box d-lg-none">
                <div className="my-3 d-flex">
                    {/* <ProgressBar min={0} max={5} now={page + 1} /> */}
                    <div className={"progress-block " + (page >= 0 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 1 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 2 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 3 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 4 ? "active" : "")} ></div>
                    <div className={"progress-block " + (page >= 5 ? "active" : "")} ></div>
                </div>
                <Row>
                    <Col xs={6} className="step">{page + 1} di 6</Col>
                    {/* <Col xs={6} className="edit" >
                        <span style={{ marginRight: 10 }}>MODIFICA</span>
                            <img src={edit} height="22px" />
                    </Col> */}
                </Row>
        </div>}
            <h1 className="title">{title}</h1>
            <div className={"subtitle " + (hideSubtitleMobile ? "d-none d-lg-block" : "")}>{subtitle}</div>
            {page !== undefined && 
                <div className="progress-desktop d-none d-lg-flex flex-row mt-3">
                    <DesktopStep past={page > 0} active={page === 0} number={1} copy="Dati abitazione" icon={page > 0 ? checkmark : undefined} />
                    <DesktopStep past={page > 1} active={page === 1} number={2} copy="Fonti energetiche" icon={page > 1 ? checkmark : undefined} />
                    <DesktopStep past={page > 2} active={page === 2} number={3} copy="Consumi attuali" icon={page > 2 ? checkmark : undefined} />
                    <DesktopStep past={page > 3} active={page === 3} number={4} copy="Report informativo" icon={report} />
                    <DesktopStep past={page > 4} active={page === 4} number={5} copy="Selezione nuove soluzioni" icon={page > 4 ? checkmark : undefined} />
                    <DesktopStep past={page > 5} active={page === 5} number={6} copy="Selezione opzioni energia" icon={page > 5 ? checkmark : undefined} />
                    <DesktopStep past={page > 6} active={page === 6} number={7} copy="Riepilogo nuove soluzioni" icon={page > 6 ? report : undefined} />
                </div>}

          
        </div> :  <Row className="d-none d-lg-block">
                <div style={{color:"white"}}>DATI</div>
                <table  style={{ fontSize: "0.625rem", color: "white"}} >
                    <tbody>
                        {sortedDebugData}
                    </tbody>
                </table>
          </Row>
        }
    </div>);
}

export default Header;