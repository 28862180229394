
import React, {useState} from 'react';
import backarrow from "../../icons/icon/ArrowBack.svg"
import RiepilogoModalComponent from './RiepilogoModalComponent';
import {
  get, set
} from '../../reducers/data';
import { useDispatch, useSelector } from 'react-redux';


function NextButtonComponent({ buttonEnabled, onClick, onBack, text, onlyContinue, extraClass }) {
  const [showRiepilogo, setShowRiepilogo] = useState(false);
  const data = useSelector(get);
  let isGoingToFotoV = false;
  if ((data["C53"] === true || data["C53"] === "Si") && 
     (data["C41"] === false || data["C41"] === undefined || data["C41"] === "No") &&
     (data["C11"] === "Monofamiliare" || data["C11"] === "Bifamiliare/Villette a schiera" || data["C11"] === "Bifamiliare") )
      isGoingToFotoV = true;

  if (onlyContinue === false)
    return   <div className="next-button-box no-border d-flex justify-content-center centered justify-content-lg-end">
                <button className="single-next" onClick={onClick}>{text}</button>
              </div>
  return (<>
    <div aria-live={"assertive"} className="text-center">
      {isGoingToFotoV ? "Premendo AVANTI si apre una nuova scheda. Verrai indirizzato al simulatore Enel X, dedicato al fotovoltaico": ""}</div>
      <div className={"next-button-box d-md-flex justify-content-md-center justify-content-lg-end " + extraClass ?? "" }> {/* messo lg-end invece de lg-between */}
        <div onClick={() => setShowRiepilogo(true)} className="riepilogo-label d-none"><span>Riepilogo</span></div> {/* d-lg-block */}
        <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
        {onBack !== undefined &&
        <img aria-roledescription="bottone" aria-label="Torna Indietro" tabIndex={0} src={backarrow} onClick={onBack} alt="back-button" className={"back d-lg-none " + (onBack ? "" : "disabled")} /> }
        <div className="d-flex">
          <button aria-roledescription="bottone" aria-label='Torna Indietro' title={"Torna Indietro"} className="d-none d-lg-block btn outline back" disabled={(onBack === undefined) ? true : false} onClick={onBack}><span aria-hidden={true}>INDIETRO</span></button>
          <button aria-roledescription="bottone" title={text === "Avanti" ? "Vai avanti" : text} disabled={(buttonEnabled === true || buttonEnabled === undefined) ? true : false} onClick={onClick}>{text}</button>
        </div>
      </div>
      </>);
}

export default NextButtonComponent;