

import React, { useState } from 'react';
import InfoModal from './InfoModalComponent';
import InfoPopup from "./InfoPopupComponent"
import info from "../../icons/icon/info2.svg";

function InfoButtonComponent(props) {
    const [showInfo, setShowInfo] = useState(false)
    return (<div 
        className={"info-button " + props.extraClass} 
        aria-label={props.title}>
        <button style={{ border:0, background: "unset"}} 
         onKeyDown={(event) => {
            if (event.key === "Escape") 
                    setShowInfo(false);
        }}
       
         onClick={() => {
                setShowInfo(true)
            } 
            } >
            <img src={info} title={props.altTitle} alt={props.altTitle ?? "informazioni su " + props.title} width="21px" height={21} id="info"
            />
        
        </button>
        {showInfo && <>
                 <InfoModal {...props} close={() => setShowInfo(false)} />
                <InfoPopup {...props} close={() => setShowInfo(false)} />
            </>}
            {showInfo &&<button onFocus={() => setShowInfo(false)} className="visually-hidden" aria-hidden="true" >
            </button>}
            <div className="visually-hidden" aria-hidden="false" aria-live={"polite"} >{showInfo && ("Finestra modale aperta " + props.title)} </div>
               

        </div>)
}

export default InfoButtonComponent;