import {useState} from "react";
import { Button, Col, Container } from "react-bootstrap";
import warning from "../../icons/icon/warning--filled.svg";
import { useSelector } from "react-redux";
import { get } from "../../reducers/data";
import ThanksComponent from "../Thanks"

import close from "../../icons/icon/close.svg";
import iconRiscaldamento from "../../icons/icon/heating.svg";
import iconAcquaCalda from "../../icons/icon/water.svg";
import iconFotovoltaico from "../../icons/icon/solare.svg";
import iconCotturaCibi from "../../icons/icon/cooking.svg";
import RiepilogoModalComponent from "./RiepilogoModalComponent";


function RisparmioBox({
  title,
  description,
  icon,
  attuale,
  futura,
  risparmio,
  rispPercentuale,
  fonte
  }) {

  const risparmioPercentuale = rispPercentuale ?? (futura && attuale && Math.abs(Math.round((attuale - futura) / attuale * 100)) + "%");
  const onlyIntegerEuroFormat = (number) => { 
    if (Number.isNaN(number)) return number;
    return (Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €").replace("-", "");
  }
  return (
    <div className="risparmio-box d-md-flex justify-content-md-center align-items-md-center ">
      <div className="d-flex col-12 col-md-6 col-lg-6 pe-md-4 top">
        <div className="img-container">
          <img src={icon} alt={""} role="presentation" />
        </div>
        <div>
          <h2 className="title my-0">{title}</h2>
          <div className="description" dangerouslySetInnerHTML={{ __html: description.replace("[FONTE]", fonte?.toLowerCase())}}></div>
        </div>
      </div>
      <div className="risparmio-details d-flex justify-content-between col-12 col-md-6 col-lg-6">
        <div className="actual-futura">
        {attuale && (
          <div className="attuale">
            <div className="mb-2">Stima spesa annua</div>
            <div className="d-flex justify-content-between">
              <div>
                <div>Attuale</div>
                <div className="nowrap b s">{onlyIntegerEuroFormat(attuale)}</div>
              </div>
              <div>
                <div>Futura</div>
                <div className="nowrap b">{onlyIntegerEuroFormat(futura)}</div>
              </div>
            </div>
          </div>
        )}
        </div>
          <div className={"risparmio " + (title === "Fotovoltaico" ? " no-border" : "") }>
            <div className="mb-2">Risparmio annuo</div>
            <div className="g">&nbsp;
              {/* {risparmioPercentuale ?? "-10%"} */}
              </div>
            <div className="b nowrap">-{onlyIntegerEuroFormat(risparmio)}</div>
          </div>
      </div>
    </div>
  );
}


function RisparmioBoxTotal({
  title,
  description,
  icon,
  attuale,
  futura,
  risparmio,
  rispPercentuale,
  fonte
  }) {

  const risparmioPercentuale = rispPercentuale ?? (futura && attuale && Math.abs(Math.round((attuale - futura) / futura * 100)) + "%");
  const onlyIntegerEuroFormat = (number) => { 
    if (Number.isNaN(number)) return number;
    return Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €";
  }
  return (
    <div className="risparmio-box risparmio-box-total d-md-flex justify-content-md-center align-items-md-center ">
      <div className="d-flex col-12 col-md-6 col-lg-6 pe-md-4 top">
        <div>
          <h2 className="title my-0">{title}</h2>
          <div className="description" dangerouslySetInnerHTML={{ __html: description.replace("[FONTE]", fonte?.toLowerCase())}}></div>
        </div>
      </div>
      <div className="risparmio-details d-flex justify-content-between col-12 col-md-6 col-lg-6">
        <div className="actual-futura">
        {attuale && (
          <div className="attuale">
            <div className="mb-2">Stima spesa annua</div>
            <div className="d-flex justify-content-between">
              <div >
                <div>Attuale</div>
                <div className="s nowrap "><b>{onlyIntegerEuroFormat(futura)}</b></div>
              </div>
              <div>
                <div>Futura</div>
                <div className="nowrap "><b>{onlyIntegerEuroFormat(attuale)}</b></div>
              </div>
            </div>
          </div>
        )}
        </div>
          <div className={"risparmio " + (title === "Fotovoltaico" ? " no-border" : "") }>
            <div className="mb-2">Risparmio annuo</div>
            <div className="g">
             {risparmioPercentuale ?? "-10%"}
            </div> 
            <div className="b nowrap">{onlyIntegerEuroFormat(risparmio)}</div>
          </div>
      </div>
    </div>
  );
}

const TabletSpacing =() => <div id="tablet-spacing" className="d-block d-lg-none">
  <div className="spacer my-32"></div>
  <div className="mb-32 text-center text-md-left">Aumenta ulteriormente il tuo risparmio annuo aggiungendo un impianto fotovoltaico</div>
</div>


function RisparmioPreliminareComponent() {
  const data = useSelector(get);
  const [showRiepilogo, setShowRiepilogo] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  let foto_risp = parseFloat(data["Foto_Risparmio"]) < 0 ? parseFloat(data["Foto_Risparmio"]) : 0
  let risc_risp = parseFloat(data["Risc_Risparmio"]) < 0 ? parseFloat(data["Risc_Risparmio"]) : 0

  let totRisp = foto_risp + risc_risp
  
  // console.log(foto_risp, risc_risp, data["Foto_Risparmio"], data["Risc_Risparmio"], totRisp)
  if (totRisp === 0)
    return <div id="thanks-fs"><ThanksComponent  error="Non abbiamo attualmente una soluzione che possa migliorare i consumi dell'edificio" /></div> 

  return (
    <Container id="risparmio-preliminare">
      {showWarning &&<div className="mt-lg-5">
        <div className="warning d-flex">
          <div className="icon me-3">
            <img src={warning} alt={""} role="presentation" />
          </div>
          <h2 className="text"><b>
            Questa è una stima del risparmio che puoi ottenere.
            </b>
              <br />Se continui, potrai selezionare le soluzioni di tuo interesse e scoprire quanto risparmiare.
          </h2>
          <div className="close">
            <img src={close} alt={"close icon"} onClick={() => setShowWarning(false)} />
          </div>
        </div>
      </div>}

       <RisparmioBoxTotal 
        title={"STIMA RISPARMIO TOTALE"}
        description={"che puoi ottenere attraverso le soluzioni proposte qui sotto."}
        futura={data["SpesaAnnuaAttuale"].replace(".",",") ?? "STUB"}
        attuale={data["SpesaAnnuaFutura"].replace(".",",") ?? "STUB"}
        risparmio={totRisp}//  data["RisparmioAnnuo"].replace(".",",") ?? "STUB"
        />


      { data["Risc_Risparmio"]?.startsWith("-") && 
        <RisparmioBox
          fonte={data["C25"]}
          title="Riscaldamento"
          description={data["C24"] === true || data["C24"] === "Si" ? "Sganciandosi dall'impianto centralizzato ed utilizzando le <b>pompe di calore</b>" : "Sostituendo la tua caldaia a [FONTE] con una <b>pompa di calore</b>"}
          icon={iconRiscaldamento}
          attuale={data["Risc_Attuale"] ?? "STUB"}
          futura={data["Risc_Futura"] ?? "STUB"}
          risparmio={data["Risc_Risparmio"] ?? "STUB"}
        />}

      {/* { data["AC_Risparmio"]?.startsWith("-") &&<RisparmioBox
        fonte={data["C26"]}
        title="Acqua calda"
        description="Sostituendo la tua caldaia a [FONTE] con una <b>pompa di calore</b>"
        icon={iconAcquaCalda}
        attuale={data["AC_Attuale"] ?? "STUB"}
        futura={data["AC_Futura"] ?? "STUB"}
        risparmio={data["AC_Risparmio"] ?? "STUB"}
      /> } */}
{/* 
      { data["Cott_Risparmio"]?.startsWith("-") &&<RisparmioBox
        fonte={data["C27"]}
        title="Cottura cibi"
        description="Sostituendo il tuo piano cottura a [FONTE] con un <b>piano cottura a induzione</b>"
        icon={iconCotturaCibi}
        attuale={data["Cott_Attuale"] ?? "STUB"}
        futura={data["Cott_Futura"] ?? "STUB"}
        risparmio={data["Cott_Risparmio"] ?? "STUB"}
      /> } */}

      <TabletSpacing />

      { data["Foto_Risparmio"] && data["Foto_Perc"] &&  data["Foto_Risparmio"] != 0 &&<RisparmioBox
        title="Fotovoltaico"
        description="Soluzione economica e conveniente per <b>produrre energia elettrica per la tua casa</b>"
        icon={iconFotovoltaico}
        rispPercentuale={(Math.abs(parseFloat(data["Foto_Perc"]) * 100))?.toFixed(0) + "%"}
        risparmio={data["Foto_Risparmio"] ?? "STUB"}
      /> }

      <div className="spacer my-32"></div>

        {/* <div className="mt-5 small text-center d-md-none">
          Aumenta ulteriormente il tuo risparmio annuo aggiungendo un impianto fotovoltaico
        </div> */}

      {/* <div className="d-lg-flex justify-content-end mt-md-4 risparmio-buttons">
        <div className="me-lg-2 d-none">
            <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
            <Button className="outline mt-xl-0 mb-xl-0 mt-4 mb-3" onClick={() => showRiepilogo(true)}>RIVEDI DATI INSERITI</Button>
          </div>
             <Button className="outline mt-3">RIVEDI DATI INSERITI</Button>
        <div>
          <a style={{ width: "100%"}} href={data["ProjectUrl"]} target="_blank" rel="noreferrer" >
            <Button className="outline mb-1">SCARICA LA SIMULAZIONE</Button>
          </a>
        </div>
      </div>
       <div className="mt-2 mb-5 small text-center text-lg-end d-lg-none">
                Scarica il report della simulazione in formato PDF
        </div> */}

        <div className="claim text-center  text-lg-end mt-3 mb-3">
        Vuoi scoprire come Enel X può aiutarti a realizzare il tuo piano di elettrificazione?
        </div>
        <div className="text-center mb-3 text-lg-end"> 
          <b>Continua per configurare gli interventi</b> che ti interessano e riceverai la soluzione più adatta a te.
        </div>

        {/* <Button className="filled mt-3">CONTINUA</Button> */}
    </Container>
  );
}

export default RisparmioPreliminareComponent;
