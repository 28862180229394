
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React from 'react';
import cibi from "../../icons/icon/cibi.svg"
import riscaldamento from "../../icons/icon/riscaldamento.svg"
import acquacalda from "../../icons/icon/acquacalda.svg"
import solare from "../../icons/icon/fotovoltaico.svg"
import utils from "../../utils/index.js"

function MultiSelectAtViewComponent({ name, cell, description, choices }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    // const currentValue = data[cell]
    const icons = [riscaldamento, acquacalda, cibi, solare]
    
    const visible = (x) => { 
        if (x.risp){
            if (parseFloat(data[x.risp]) > 0)
                return false
        }
        if (x.text === "Energia solare"){
            // if (data["C11"] !== "Monofamiliare" && data["C11"] !== "Bifamiliare")
            //     return false
            if (data["PresenzaFotovoltaico"] === "Si" || data["PresenzaFotovoltaico"] == true)
                return false
        }

        if (!x.conditionalRendering)
            return true
        else 
            return utils.testRendering(x, data)
    }

    const keyDown = (e,x) => {
        if (e.key === "Enter") {
            dispatch(set({
                cell: x.cell,
                value: !data[x.cell]
            }))
        }
        if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
            e.preventDefault();
            const sibling = e.currentTarget.previousElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
        } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
            e.preventDefault();
            const sibling = e.currentTarget.nextElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
        }
    }

    const onClick= (e, x) => {
        dispatch(set({
            cell: x.cell,
            value: !data[x.cell]
        }))
    }

    return (
        <div className={"selectatview-component mb-16 " + (data ? "filled" : "")}>
            <div className="title">{name}
                {description && <div className="description">{description}</div>}
            </div>
            {choices && <div className="choice-box" role="list">
                {choices.filter(x => visible(x)).map((x, i) =>
                    <div key={i} role="listitem" onClick={(e) => onClick(e, x)} 
                    onKeyDown={(e) =>keyDown(e, x)}
                    className={"single-box d-flex align-items-center " + (data[x.cell] === true ? "selected" : "") } >
                        <div>
                        <input type="checkbox" 
                            className="choice" 
                            aria-label={"Opzione " + x.text} 
                            readOnly={true}
                            checked={data[x.cell] == true ? true : false}
                           ></input> 
                            </div>
                            <div>
                                <div className='d-flex align-items-center'>
                        <img src={icons[x.image]} width="32" height={32} role="presentation" alt="" /> <br />
                        <div style={{lineHeight: "2rem"}}>{x.text}</div>
                        </div>
                        </div>
                    </div>
                )
                }
            </div>}
        </div>
    );
}

export default MultiSelectAtViewComponent;