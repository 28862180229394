import React, {useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ContactsModalComponent from '../ContactsModalComponent';

function BookAppointment ({changeModal}) {
    const [showModal, setShowModal] = useState(false);
    return <Container fluid className="book-appointment">
        <Container>
        <Row> 
            <Col>
                <h2>Prenota un appuntamento con un nostro operatore per finalizzare la tua soluzione</h2>
                {/* <p>Scegli la fascia oraria in cui intendi essere ricontattato</p> */}
            </Col>
            <Col className="d-flex justify-content-center align-items-md-center justify-content-md-center" style={{ position:"fixed", bottom: "10px", left: 0, right: 0 }}  >
                <button className="outline-btn" onClick={() => changeModal(true)}>RICEVI IL REPORT</button>
            </Col>
        </Row>
        </Container>
    </Container>
}

export default BookAppointment;